/* base styles
================================================== */
* {
  margin: 0;
  padding: 0;
  outline: 0;
}

body,
html {
  height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #3A3636;
}

body.open {
  overflow: hidden;
}

:focus {
  outline: 0
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  border: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.space {
  padding: 70px 0;
}

.c-red {
  color: #E3001B;
}

.t-underline {
  text-decoration: underline !important;
}

.b-color-1 {
  border: 1px solid #B4B5B5!important;
}

.b-radius-1 {
  border-radius: 12px!important;
}


/* typography
================================================== */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  color: #222;
}

h1 {
  font-size: 26px;
  line-height: 32px;
}

h2 {
  font-size: 22px;
  line-height: 28px;
}

h3 {
  font-size: 18px;
  line-height: 28px;
}

h4 {
  font-size: 18px;
  line-height: 28px;
}

h5 {
  font-size: 18px;
  line-height: 28px;
}

h6 {
  font-size: 18px;
  line-height: 28px;
}

strong {
  font-weight: 700;
}

.fs-06 {
  font-size: 0.6rem;
}

a {
  color: #5B5A63;

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

a:hover,
a:active,
a:focus {
  color: #E3001B;
  outline: 0;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
  color: #3A3636;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}

.heading {
  margin: 0 0 60px 0;
  color: #1E1D29;
  font-size: 43px;
  line-height: 52px;
  font-weight: 700;
  text-align: center;
}

.button {
  padding: 12px 50px;
  border: 0;
  box-shadow: none;
  outline: none;
  text-transform: uppercase;
  border-radius: 50px;
  display: inline-block;
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-appearance: none;
  appearance: none;
}

.button i {
  margin-left: 2px;
}

.button img {
  margin-bottom: 5px;
}

.button-primary {
  background-color: #E3001B;
  color: #fff;
}

.button-primary:hover {
  color: white;
  background: #FC0A27;
}

.button-secondary {
  background-color: #00ACC0;
  color: #fff;
}

.button-secondary:hover {
  background-color: #0394a5;
  color: #fff;
}

.button-outline {
  padding: 12px 35px;
  box-shadow: none;
  outline: none;
  text-transform: uppercase;
  border-radius: 50px;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.button-outline i {
  margin-left: 4px;
}

.button-outline-white {
  border: 2px solid #fff;
  color: #fff;
}

.button-outline-white:hover {
  background-color: #fff;
  color: #3A3636;
}

.button-outline-primary {
  border: 2px solid #E3001B;
  color: #E3001B;
}

.button-outline-secondary {
  border: 2px solid #E3001B;
  color: #E3001B;
  background: #fff;
}

.button-outline-primary:hover,
.button-outline-secondary:hover {
  background-color: #E3001B;
  color: #fff;
}

.outside-header {
  padding-top: 84px;
}

.outside-header header {
  background-color: #fff;
  box-shadow: 0 10px 6px rgba(0, 0, 0, .02);
  padding: 8px 0;
}

.outside-header header .logo {
  display: block;
  top: 0;
  //margin-left: -34px;
}

.outside-header header .logo img {
  height: 70px;
}

.outside-header header nav ul li a {
  color: #4E4B63;
}

.outside-header header nav ul li a:hover {
  color: #E3001B;
  opacity: 1;
}

header {
  width: 100%;
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .6s;
  z-index: 10001;
}

header.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  transform: translateY(-115%);
  box-shadow: 0 10px 6px rgba(0, 0, 0, .02) !important;
  background-color: #fff;
  display: none;
  padding: 8px 0;
}

header.fixed-block {
  display: block;
}

header.fixed-show {
  transform: translateY(0);
}

header .logo img {
  height: 105px;
}

header.fixed .logo img {
  height: 69px;
}

header.fixed .logo {
  display: block;
  //margin-left: -34px;
}

header .logo img.white-logo {
  display: none;
}

header nav {
  text-align: right;
}

header nav ul {
  display: inline-block;
}

header nav ul li {
  display: inline-block;
  margin: 0 20px;
  position: relative;
}

header nav ul li a {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

header.fixed nav ul li a {
  color: #1E1D29;
}

header.fixed nav ul li a:hover {
  color: #E3001B;
}

header.fixed nav ul li a.button,
body.outside-header header nav ul li a.button {
  color: #fff !important;
}

header.fixed-show .nav-btn span {
  background: #000000;
}

header img.fixed-phone {
  display: none;
}

header nav ul li.mega-menu {
  padding-bottom: 13px;
}

header nav ul li .dropdown {
  position: absolute;
  background: #fff;
  width: 260px;
  border-radius: 19px;
  text-align: left;
  top: 35px;
  left: 50%;
  margin-left: -130px;
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
  padding: 20px 5px 15px 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  ul {
    width: 100%;
  }
}

header nav ul li:hover .dropdown {
  opacity: 1;
  pointer-events: initial;
}

header nav ul li .dropdown li {
  text-align: left;
  display: block;
  margin-bottom: 10px;
}

header nav ul li .dropdown li a {
  color: #1E1D29;
  display: block;
  text-transform: initial;
}

header nav ul li .dropdown li a:hover {
  color: #E3001B;
}

header.fixed-block img.fixed-phone,
body.outside-header header img.fixed-phone {
  display: inline-block;
}

header.fixed-block img.phone,
body.outside-header header img.phone {
  display: none;
}

header .phone-info {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 37px;
  margin-right: -245px;

  &:hover {
    opacity: 0.7;
  }
}

header.fixed-block .phone-info,
body.outside-header header .phone-info {
  margin-top: 20px;
}

header .phone-info img, {
  width: 25px;
  margin-right: 6px;
  margin-top: -4px;
}

header .phone-info a {
  //color: #373541;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

header.fixed-block .phone-info a,
.outside-header header .phone-info a {
  color: #E3001B;
}

.intro {
  padding: 10px 50px 50px 50px;
  //background: url("../images/intro.jpg") center bottom no-repeat;
  background-size: cover !important;
  position: relative;
  min-height: 840px;
  overflow: hidden;
}

.intro .intro-container {
  width: 100%;
  max-width: 1500px;
  height: 585px;
  position: relative;
  margin: 0 auto;
  ////  TODO: while creating responsive - display: none
  //  display: none;
}

.intro .intro-text {
  position: absolute;
  width: 38%;
  right: 0;
  bottom: -18%;
  text-align: right;
  z-index: 2;
}

.intro .intro-text h1 {
  font-weight: 600;
  font-size: 50px;
  line-height: 56px;
  color: #fff;
  margin: 0 0 10px 0;
  word-break: break-word;
}

.intro .intro-text p {
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin: 0 0 30px 0;
}


.calculator {
  width: 440px;
  position: absolute;
  top: 208px;
  left: -80px;
}

.logo-calculator {
  position: absolute;
  top: 72px;
  left: 266px;
  z-index: 2;
}

.calculator-holder {
  position: relative;
}

.calculator-holder:before {
  content: "";
  background: url("../images/calculator-bgr2.png") center center no-repeat;
  background-size: 100%;
  position: absolute;
  top: -110px;
  left: 0;
  width: 1220px;
  height: 1147px;
  z-index: 1;
  margin-left: -499px;
}

.calculator-holder .calculator-holder-bgr {
  position: absolute;
  top: 0;
  max-width: 900px;
}

.calculator-holder svg {
  height: 191px;
  position: absolute;
  top: 241px;
  left: 90px;
  z-index: 2;
  width: 142px;
}

.calculator-holder svg text {
  pointer-events: none;
  font-size: 17px;
}

.calculator-holder svg path {
  fill: #ECF1F3;
  cursor: pointer;

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.calculator-holder svg g {

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.calculator-holder svg g.active path,
.calculator-holder svg g:hover path {
  fill: #fff;
  stroke: #E3001B;
  stroke-width: 1;
}

.calculator-holder .nav-amounts-sec {
  position: absolute;
  top: 181px;
  transform: rotate(30deg);
  z-index: 10;
  left: 22px;
  width: 645px;
  border-radius: 10px 10px 0 0;
  padding: 5px 0;
  text-align: center;
  text-transform: uppercase;
}


.calculator-holder .nav-amounts-sec a {
  color: #CB2129;
  font-weight: 700;
  font-size: 18px;
}

.calculator-holder .nav-amounts-sec a.active {
  display: none;
}

.calculator-holder .nav-amounts-sec a:hover {
  color: #FC0A27;
}

.calculator-holder .nav-amounts-sec a:hover span.next {
  background: #FC0A27;
}

.calculator-holder span.next {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background: #E3001B;
  color: #fff;
  position: relative;
  left: 6px;
  line-height: 29px;
  font-size: 18px;
  bottom: 1px;
}

.calculator-holder span.next img {
  width: 8px;
  display: inline-block;
  text-align: center;
  margin-bottom: 3px;
}


.calculator .button {
  width: 100%;
  margin: 10px auto 2px;
  display: table;
  padding: 8px 15px;
  line-height: 31px;
  letter-spacing: 1px;
}

.calculator .button span.next {
  background: #fff;
  color: #E3001B;
  position: relative;
  top: 1px;
}

.calculator form {
  position: relative;
  z-index: 2;
  padding: 0 10px;
}

.calculator .icheck-success label {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin: 0 0 6px 0;
}

.calculator .icheck-success label a {
  color: #707070;
}

.calculator .icheck-success label:hover a {
  color: #E3001B;
}

.calculator .buttons {
  min-width: 520px;

  button {
    margin: 10px 0 2px 0;
    font-size: 13px;
    letter-spacing: 0;
    min-width: 250px;
    padding: 10px 17px;
    height: 51px;
    line-height: 28px;
    width: auto;
  }
}

.intro .calculator #nav-home2 .icheck-success label.invalidField {
  bottom: -18px;
}

.intro .calculator .icheck-success.credit-insurance input:checked + label.invalidField:before {
  bottom: 81px;
}

.intro .calculator .icheck-success.credit-insurance input:checked + label.invalidField:after {
  top: -82px !important;
}

.calculator .icheck-success.credit-insurance {
  position: absolute;
  right: 0;
  margin-right: -170px;
  top: 0;

  .insurance-info {
    font-size: 13px;
    line-height: 18px;
    padding-left: 27px;
    transition: all .4s;
    overflow: hidden;
    height: 0;

    &.open {
      height: auto;

    }

    div {
      margin-bottom: 4px;
    }
  }
}

.calculator .nav-instalments {
  margin: 0 0 15px 0;
  position: relative;
  z-index: 10;
}

.calculator .nav-instalments a {
  padding: 0 0 0 26px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #707070;
  position: relative;
  margin-right: 20px;
}

.calculator .nav-instalments a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #C0C6C8;
  margin-top: 1px;
}

.calculator .nav-instalments a.active:before {
  border-color: #CB2129;
}

.calculator .nav-instalments a:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 4px;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background: #CB2129;
  display: none;
}

.calculator .nav-instalments a.monthly-tab {
  border-radius: 0 88px 88px 0;
}

.calculator .nav-instalments a.weekly-tab {
  border-radius: 88px 0 0 88px;
}

.calculator .nav-instalments a.active:after {
  display: block;
}

.calculator .slider-holder {
  padding-bottom: 25px;
}

.calculator .slider-holder .selected-val {
  font-size: 14px;
  line-height: 22px;
  color: #707070;
  margin: 0 0 -12px 0;
  text-align: left;
}

.intro .calculator form .form-group {
  margin-bottom: 15px;
}


.intro .calculator-holder form .form-group .form-control {
  border-color: #C0C6C8;
  padding: 8px 12px;
}

.intro .calculator-holder form .form-group .form-control::placeholder {
  color: #B7B7B9;
}

.intro .calculator-holder form .icheck-success {
}

.irs-single {
  display: none;
}

.calculator .slider-holder .selected-val span {
  font-size: 22px;
  line-height: 27px;
  font-weight: 700;
  color: #707070;
  display: inline-block;
  margin: 0 3px;
}

.irs--round .irs-min,
.irs--round .irs-max {
  top: 45px;
  background-color: transparent;
  color: #707070;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

.irs--round .irs-handle {
  top: 13px;
  width: 30px;
  height: 30px;
  border: 0;
  cursor: pointer;
}


.irs--round .irs-line {
  top: 25px;
  height: 11px;
  background-color: #dee4ec;
  border-radius: 6px;
}

.irs--round .irs-min,
.irs--round .irs-max {
  visibility: visible !important;
}

.irs--round .irs-bar {
  top: 25px;
  height: 12px;
  background-color: #00ACC0;
  border-radius: 20px;
}

.intro .intro-container .tab-content:first-child .irs--round .irs-handle:after,
.term-and-amount .calculator-holder .tab-content:first-child .irs--round .irs-handle:after {
  content: "";
  background: url("../images/slide-arrow.svg") center no-repeat;
  position: absolute;
  z-index: 100;
  background-size: 100%;
  width: 36px;
  height: 36px;
  top: 50%;
  margin-top: -19px;
  left: 50%;
  margin-left: -21px;
}

.intro .intro-container .tab-content:first-child .irs--round .irs-handle:hover:after,
.term-and-amount .calculator-holder .tab-content:first-child .irs--round .irs-handle:hover:after {
  opacity: 0.7;
}

//
//.intro .intro-container .slider-holder .irs--round .irs-handle:after,
//.term-and-amount .calculator-holder .slider-holder .irs--round .irs-handle:after {
//  content: "";
//  background: url("../images/icons/clock-calc.svg") center no-repeat;
//  position: absolute;
//  z-index: 100;
//  background-size: 100%;
//  width: 24px;
//  height: 24px;
//  top: 50%;
//  margin-top: -12px;
//  left: 50%;
//  margin-left: -12px;
//}

.calculator-total {
  padding: 0;
}

.calculator .selected-val {
  font-size: 14px;
  line-height: 1.5;
}

.calculator-total .selected-val span {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  margin: 0 0 0 6px;
}

.calculator-total .selected-val span.sum {
  color: #E3001B;
}

.calculator-total .contract-info {
  display: table;
  margin: 12px auto 0;
  color: #707070;
  font-size: 13px;
  line-height: 22px;
}

.calculator-total .contract-info:hover {
  color: #E3001B;
}

.sec-intro.see-how-intro {
  background: url("../images/see-how-bgr-mask.png") no-repeat, url("../images/see-how-bgr-mask2.png") no-repeat, url("../images/see-how-bgr.png") no-repeat;
  background-size: cover;
  position: relative;
}


.get-benefits {
  position: relative;
}

.see-how-page.get-benefits:after {
  content: "";
  background: url(../images/left-fragment.svg) top left fixed no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100%;
  background-position-y: -58px;
  top: 0;
  left: -8%;
  z-index: -1;
}

.product-details-page.how-to-get:after {
  content: "";
  background: url(../images/product-details-fragment.svg) bottom left no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -10%;
  bottom: -10%;
  z-index: -1;
}


.how-to-get {
  position: relative;
  z-index: 20;
}

.home-page.how-to-get:after {
  content: "";
  background: url(../images/right-fragment.svg) top right no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.see-how-page.how-to-get:after {
  content: "";
  background: url(../images/right-fragment.svg) top right no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.how-to-get .heading {
  margin: 0;
}

.how-to-get .sticky-top {
  top: 45px;
  margin-top: 20px;
}

.how-to-get .sticky-top img:last-child {
  display: none;
}

.how-to-get .sticky-top img:hover {
  animation: shake 0.82s cubic-bezier(0.36, 2.07, 0.19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.how-to-get .step:first-child {
  margin-top: 124px;
}

.how-to-get .step {
  height: 400px;
}

.how-to-get .num {
  color: #F5DFDD;
  font-size: 160px;
  line-height: 160px;
  font-weight: 200;
}

.how-to-get .lab {
  color: #E3001B;
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.how-to-get .val {
  font-size: 18px;
  line-height: 24px;
}

.how-to-get .steps h6 {
  color: #1E1D29;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 700;
  margin: 0 0 15px 0;
}

.stage .small-heading {
  margin: 0 100px 15px 0;
}

.stage .stage-desc {
  color: #3A3636;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0 50px 0 0;
  margin: 0 0 25px 0;
}

.stage.stage-phone .stage-link {
  padding: 0 50px 0 0;
}

.stage.stage-phone .stage-link a {
  color: #E3001B;
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
}

//.stage.stage-phone .stage-link img {
//  height: 28px;
//  position: relative;
//  top: -9px;
//  margin-right: 6px;
//}

.stage.stage-phone .stage-link span {
  color: #706F74;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  display: block;
  margin-left: -39px;
}

.stage.stage-address .stage-link {
  color: #706F74;
  font-size: 18px;
  line-height: 26px;
  text-decoration: underline;
}

.stage.stage-address .stage-link img {
  margin-right: 7px;
  height: 20px;
  position: relative;
  top: -2px;
}

.stage.stage-address .stage-link:hover {
  text-decoration: none;
  color: #E31E24;
}


.get-money {
  background: url("../images/get-money-bgr.jpg") center center no-repeat;
  background-size: cover;
  text-align: center;
}

.loan-repayment {
  text-align: center;
}

.loan-repayment .heading {
  margin: 0 0 15px 0;
}

.loan-repayment .sub-heading {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0 0 80px 0;
}

.get-money .heading {
  color: #fff;
}

.loan-repayment img,
.get-money img {
  height: 80px;
  margin: 0 0 15px 0;
}

.loan-repayment .lab {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
}

.get-money .lab {
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  padding: 0 30px;
}

.loan-repayment .lab span,
.get-money .lab span {
  display: block;
}

.get-money .lab a {
  display: block;
  color: #fff;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin: 5px 0 0 0;
  text-decoration: underline;
}

.get-money .lab a:hover {
  text-decoration: none;
}

.credits-info {
  background: url("../images/credits-bgr.webp") center center no-repeat;
  background-size: cover;
  padding-bottom: 200px;
}

.credits-info .heading {
  text-align: left;
  margin: 0 0 50px 0;
  color: #fff;
}

.credits-info p {
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
}

.credits h2 {
  color: #1E1D29;
  font-size: 30px;
  line-height: 1.5;
  font-weight: 700;
}

.credits p {
  margin: 0 0 30px 0;
  line-height: 1.8;
}

.credits .owl-credits {
  margin: -100px 0 100px 0;
}

.owl-credits.owl-carousel .owl-stage-outer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 15px);
  width: 25%;
  background-color: #fff;
}


.owl-carousel .owl-stage-outer .owl-stage {
  padding-left: 0 !important;
}

.owl-carousel .owl-stage-outer {
  padding-bottom: 15px;
}

.owl-carousel .owl-stage-outer:after {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 12.5%;
  width: 30px;
  height: 30px;
  margin-left: -40px;
  background-color: #fff;
  transform: rotate(45deg);
  z-index: -1;
}

.owl-nav {
  position: absolute;
  top: 50%;
  margin-top: -40px !important;
  width: 100%;
}

.blog .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #e3001b !important;
}

.blog .owl-theme .owl-nav [class*=owl-] {
  color: #fff !important;
  background-color: #e3001b !important;
}

.credits .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #fff !important;
}

.credits .owl-theme .owl-nav [class*=owl-] {
  color: #333 !important;
  background-color: #fff !important;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  collapse: #fff;
  opacity: .8;
}

.owl-theme .owl-nav [class*=owl-] {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 100% !important;
  font-size: 30px !important;
  line-height: 1 !important;
  margin: 0 !important;

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.owl-theme .owl-nav button span {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 36px;
}

.owl-theme .owl-nav .owl-prev {
  left: -44px;
}

.owl-theme .owl-nav .owl-next {
  right: -44px;
}

.credits {
  padding-bottom: 100px;
  position: relative;
}

.home-page.credits:after {
  content: "";
  background: url("../images/credits-shape.svg") bottom left no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.credits .owl-credits .item {
  min-height: 86px;
  background-color: rgba(255, 255, 255, .7);
  text-align: center;
  padding: 15px 0;
  border-right: 1px solid #999;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.credits .owl-credits {

}

.credits .lab {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 5px 0;
  text-transform: uppercase;
}

.credits .val {
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
}

.blog .blog-item {
  background-color: #fff;
  border-radius: 18px;
  margin-top: 30px;
}

.blog.slider .blog-item {
  margin-top: 0;
}

.blog .blog-item .blog-img {
  overflow: hidden;
  border-radius: 18px 18px 0 0;
}

.blog .blog-item .blog-img img {
  border-radius: 18px 18px 0 0;
  transition: transform .4s;
  width: 100%;
  height: 175px;
}

.blog .blog-item .blog-img img:hover {
  transform: scale(1.1);
}

.blog .blog-item .blog-content {
  padding: 25px;
  text-align: center;
}

//.blog .blog-item .blog-content h3 {
//  margin-bottom: 15px;
//  font-weight: 500;
//  min-height: 140px;
//}

.blog.slider .owl-theme .owl-nav {
  display: block;
}

.blog.slider .owl-theme .owl-nav .owl-next {
  right: -41px;
}

.blog.slider .owl-theme .owl-nav .owl-prev {
  left: -41px;
}

.blog .blog-item .blog-content h3 {
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin: 0 0 20px 0;
  min-height: 135px;
  position: relative;
  overflow: hidden;
  font-weight: 500;
}

.blog .blog-item .blog-content h3 a {
  color: #3F3E4B;
}

.blog .blog-item .blog-content h3 a:hover {
  color: #E3001B;
}

.blog .blog-item .blog-content .button-outline {
  margin: 0 auto 5px;
  display: table;
}

.blog ul.pagination {
  justify-content: center;
}


.short-contact {
  position: relative;
}

.short-contact:after {
  content: "";
  background: url("../images/short-contact-shape.svg") top right no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.short-contact .info-block {
  background-size: cover;
  border-radius: 18px;
  padding: 30px 20px;
  text-align: center;
  display: block;
}

.short-contact .info-block.find-office {
  background: url("../images/info-block-office-bgr.jpg") center center no-repeat;
  background-size: cover;
}

.short-contact .info-block.email {
  background: url("../images/info-block-email-bgr.jpg") center center no-repeat;
  background-size: cover;
}

.short-contact .info-block.phone {
  background: url("../images/info-block-phone-bgr.jpg") center center no-repeat;
  background-size: cover;
}

.short-contact .info-block img {
  margin: 0 0 15px 0;
  height: 70px;
}

.short-contact .info-block .val {
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  min-height: 70px;
  padding: 0 30px;
}

.short-contact .info-block .val span {
  display: block;
}

.short-contact .info-block .val small {
  font-size: 13px;
  line-height: 14px;
  display: block;
}


.short-social {
  margin: 50px auto 0;
  display: table;
}

.short-social li {
  margin: 0 7px;
  display: inline-block;
}

.short-social li a {
  color: #fff;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #97979E;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
}

.short-social li a:hover {
  background-color: #E3001B;
  color: #fff;
}

.newsletter {
  background-color: #E3001B;
  padding: 70px 0;
}

.newsletter .lab {
  font-size: 36px;
  line-height: 46px;
  color: #fff;
  font-weight: 600;
}

.newsletter .lab span {
  display: block;
}

.newsletter form {
  position: relative;
  margin: 0 0 20px 0;
}

.newsletter form .form-control {
  border-radius: 30px;
  border: 0;
  height: 50px;
  padding: 0 180px 0 20px;
}

.newsletter form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.newsletter form button {
  position: absolute;
  top: 15px;
  right: 0;
  font-weight: 700;
  background-color: transparent;
  border: 0;
  color: #E3001B;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
  text-transform: uppercase;

  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.newsletter form button:hover {
  color: #3D3C47;
}

.newsletter .hint {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-weight: 400;
}

.newsletter p.alert {
  display: none;
}

.newsletter p.alert.alert-danger {
  display: block;
}


footer {
  background-color: #3D3C47;
  padding: 50px 0 30px;
}

footer .lab {
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 20px 0;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

footer .footer-menu li {
  margin: 15px 0;
}

footer .footer-menu li a {
  color: #DBD6D6;
  font-size: 16px;
  line-height: 1.5;
  display: block;
}

footer .footer-menu li a:hover {
  color: #fff;
}

footer .social li {
  margin: 0 6px 0 6px;
  display: inline-block;
}

footer .social li a {
  color: #3D3C47;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
}

footer .social li a:hover {
  color: #E3001B;
}

footer .footer-bot {
  color: #fff;
  padding: 30px 0 0 0;
  margin: 40px 0 0 0;
  font-size: 14px;
  line-height: 1.5;
  border-top: 1px solid #666666;
}

footer .honor-block img {
  max-width: 165px;
}

footer .honor-block .badge2 {
  max-width: 195px;
}


/*products*/

.small-heading {
  color: #1E1D29;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 10px;
  font-weight: bold;
}

.text-title {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 5px;
  color: #1E1D29;
  padding-right: 10px;
}

.info {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 25px;
  color: white;
}

.info.red {
  color: #E3001B;
}

ul.list-info {
  margin-bottom: 30px;
}

ul.list-info li {
  position: relative;
  font-size: 16px;
  padding-left: 30px;
  margin: 0 0 7px 0;
}

ul.list-info li:before {
  content: "";
  background-size: 18px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 3px;
  left: 0;
}

img.arrow {
  margin-left: 5px;
  width: 14px;
}

/*.text {*/
/*    margin-top: 115px;*/
/*}*/

.text a {
  font-weight: 600;
}

/*button.outline-red {*/
/*    border-color: #E3001B;*/
/*    color: #E3001B;*/
/*    font-weight: 600;*/
/*}*/

/*a.blue {*/
/*    border-color: #00ACC0;*/
/*    background: #00ACC0;*/
/*    color: white;*/
/*    font-weight: 600;*/
/*}*/

/*a.blue:hover {*/
/*    background: #0394a5;*/
/*    border-color: #0394a5;*/
/*    color: white;*/
/*}*/

/*a.white {*/
/*    border-color: #E3001B;*/
/*    color: #E3001B;*/
/*    font-weight: 600;*/
/*}*/

/*a.white:hover {*/
/*    color: #E3001B;*/
/*}*/

.sec-intro.intro-products {
  background: url("../images/product-bgr-mask.png") no-repeat,
  url("../images/product-bgr-mask2.png") no-repeat,
  url("../images/product-bgr.png") no-repeat;
  background-size: cover;
}


/*vivacredit-now*/
.vivacredit-now .money-info {
  margin-top: 115px;
}

.vivacredit-now .money-info .text-title {
  color: #1E1D29;
  padding-right: 20px;
  margin-bottom: 10px;
}

.vivacredit-now .money-info ul li {
  color: #3A3636;
}

.vivacredit-now .money-info ul li:before {
  background: url("../images/icons/list-component.svg") no-repeat;
}


.viva-red {
  background: url("../images/standard-bgr.png") no-repeat, url("../images/standard-mask.png") no-repeat;
  background-size: cover;
}

.viva-red .small-heading,
.viva-red ul li,
.viva-red .list-info p {
  color: #fff;
}

.viva-white .info-text ul li:before {
  background: url("../images/icons/list-component.svg") no-repeat;
}

.vivacredit-standard .money-info ul li:before {
  background: url("../images/icons/list-component-white.svg") no-repeat;
}


/*product-details page*/
.sec-intro.intro-products.product-details {
  background: url("../images/product-details-mask2.png") no-repeat,
  url("../images/product-details-mask.png") no-repeat,
  url("../images/product-details-bgr.webp") no-repeat;
  background-size: cover;
}


/*product-info*/


.product-info {
  padding: 60px 0 70px;
  position: relative;
}

.product-info:after {
  content: "";
  background: url("../images/mask-bgr.png") no-repeat center right;
  width: 100%;
  min-height: 600px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.product-info .product-desc p {
  margin: 0 0 40px 0;
}

.product-info .product-desc p:last-child {
  margin-bottom: 0;
}

.product-info .product-desc a {
  margin-right: 20px;
}

.product-info .product-features .feature:first-of-type {
  padding-top: 0;
}

.product-info .product-features .feature {
  padding: 20px 0;
  border-bottom: 1px solid #707070;
}

.product-info .product-features .feature .lab img {
  margin-right: 12px;
  position: relative;
  top: -3px;
  height: 25px;
}

.product-info .product-features .feature .lab {
  color: #E3001B;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.product-info .product-features .feature .val {
  color: #484646;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}


/*.credit-details:after {*/

/*}*/

/*.credit-details p {*/
/*    color: #3A3636;*/
/*    font-weight: 500;*/
/*    line-height: 30px;*/
/*    margin-bottom: 28px;*/
/*    padding-right: 13px;*/
/*}*/


/*.credit-details ul {*/
/*    padding-left: 112px;*/
/*}*/

/*.credit-details ul li {*/
/*    color: #E3001B;*/
/*    font-weight: 600;*/
/*    font-size: 18px;*/
/*    border-bottom: 1px solid #707070;*/
/*    margin-bottom: 23px;*/
/*}*/

/*.credit-details ul li img {*/
/*    margin-right: 5px;*/
/*    vertical-align: bottom;*/
/*}*/

/*.credit-details ul li p {*/
/*    margin: 8px 0 19px 0;*/
/*    line-height: 24px;*/
/*}*/


/*how-to-apply*/
.how-to-get.how-to-apply h3 {
  margin: 0 0 80px 0;
}

.how-to-apply .stage {
  margin: 80px 0 0 0;
}

.how-to-apply .stage-desk {
  color: #3A3636;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  padding: 0 50px 0 0;
  margin: 0 0 25px 0;
}

.how-to-apply .button {
  font-weight: 600;
}

.how-to-apply .button:hover {
  color: white;
}


/*typical-example*/
.typical-example {
  position: relative;
  background: url("../images/typical-example-bgr.png") no-repeat, url("../images/typical-example-bgr2.png") no-repeat, url("../images/typical-example-bgr3.png") no-repeat;
  background-size: cover;
}

.typical-example .typical-title {
  text-align: left;
  margin: 0 0 32px 0;
  text-transform: uppercase;
  color: #fff;
}

.typical-example .typical-info {
  text-transform: uppercase;
  color: white;
  font-size: 30px;
  line-height: 38px;
}

.typical-example p {
  color: #fff;
  font-weight: 400;
  margin: 0 0 35px 0;
}

.typical-example a {
  color: white;
  text-decoration: underline;
  margin-right: 30px;
  font-size: 18px;
}

.typical-example a:hover {
  opacity: 0.7;
}


/*home-part*/

.home-part .calculator-total .selected-val {
  text-align: center;
}

.home-part form .form-control {
  border: 1px solid #818D92;
  border-radius: 43px;
  padding: 16px 8px 15px 35px;
  font-size: 15px;
  height: auto;
}

.home-part form .form-control::placeholder {
  color: #706F74;
}

.home-part .calculator .icheck-success label {
  width: 219px;
}

.home-part .calculator-holder svg path {
  fill: white;
  stroke: #E3001B;
}

.home-part .calculator-holder svg g.active path,
.home-part .calculator-holder svg g:hover path {
  fill: #ECF1F3;
  stroke: none;
}

.home-part .form-group {
  position: relative;
}

// CALCULATOR BIG AMOUNTS FORM
//.home-part .form-group img {


form[name=calculator_big_amounts] .selected-val {
  text-align: center;
}

form[name=calculator_big_amounts] input::placeholder {
  padding-left: 10px
}

// END CALCULATOR BIG AMOUNTS FORM

/*how-to-get sec*/

.how-to-get.sec:after {
  content: "";
  background: url("../images/path-bgr3.png") center left fixed no-repeat;
  background-size: 85%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}


/*apply-credit*/
.apply-credit {
  position: relative;
  background: #F1F5F6;
  padding-bottom: 160px;
}

.apply-credit h3 {
  text-align: left;
  margin: 0 0 25px 0;
}

.apply-credit p {
  color: #3A3636;
  font-size: 16px;
  line-height: 1.5;
}

.apply-credit .register-link {
  color: #1E1D29;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 3px;
}

.apply-credit .register-link a {
  color: #1E1D29;
  text-decoration: underline;
}

.apply-credit .register-link a:hover {
  color: #E3001B;
}

.apply-credit .term-and-amount {
  position: relative;
  background: white;
  padding: 12px 23px 17px 23px;
  border-radius: 12px;
  box-shadow: 0 6px 9px 4px rgba(128, 127, 128, 0.11);

}

.apply-credit .term-and-amount .term-and-amount-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
  z-index: 3;
}

.apply-credit .term-and-amount .term-and-amount-info p {
  margin: 0;
  color: #3F3E4B;
  font-weight: 600;
  max-width: 126px;
  line-height: 19px;
}

.apply-credit .term-and-amount .term-and-amount-info button {
  padding: 11px 20px 9px 20px;
  background: white;
}

.apply-credit .term-and-amount .term-and-amount-info button:hover {
  background: #E3001B;
}

.apply-credit .term-and-amount .term-and-amount-info a:hover {
  background: #E3001B;
  color: white;
}

.apply-credit .term-and-amount span {
  color: #3F3E4B;
  font-weight: 700;
  margin-right: 10px;
  font-size: 17px;
}


.apply-credit h5 {
  color: #1E1D29;
  font-weight: 600;
  margin: 37px 0 20px 0;
  font-size: 23px;
  line-height: 32px;
}

.apply-credit.call-me h5 {
  color: #1E1D29;
  font-weight: 600;
  //margin: 37px 0 20px 0;
  margin: 0 0 50px 0;
  //font-size: 23px;
  font-size: 19px;
  line-height: 32px;
}

.apply-credit a.call {
  margin-top: 37px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.apply-credit a.call img {
  margin-right: 10px;
  vertical-align: inherit;
}


.apply-credit a.call:hover .arrow-red {
  display: none;
}

.apply-credit a.call .arrow-white {
  display: none;
}

.apply-credit a.call:hover .arrow-white {
  display: inline-block;
}

.apply-credit form {

}

form .form-group {
  margin-bottom: 20px;
}

form .form-group label {
  color: #3F3E4B;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 3px;
  display: block;
}

form .form-group select {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  background: url("../images/icons/arrow-select.svg") no-repeat right, white;
  background-position-x: 93%;
  background-size: 12px;
  width: 100% !important;
}

form select::-ms-expand {
  display: none;
}

/*form .form-group select:after {*/
/*    content: "";*/
/*    background: url("../images/icons/arrow-select.svg") no-repeat;*/
/*    background-size: 100%;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*    right: 0;*/
/*    top: 50%;*/
/*    margin-top: -10px;*/
/*}*/

form .form-group .form-control {
  border: 1px solid #818D92;
  border-radius: 30px;
  padding: 8px 22px;
  font-size: 13px;
  height: auto;
  color: #706F74;
}

form .form-group .form-control::placeholder {
  color: #706F74;
}

form .form-group.income .form-control {
  border: 1px solid #E3001B;
  color: #E3001B;
}

form .form-group.income .form-control::placeholder {
  color: #E3001B;
}

form .form-group.income span {
  color: #E3001B;
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
  display: block;
}


.policy [type="radio"]:checked + label,
.policy [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.policy [type="radio"]:checked + label:before {
  border: 2px solid #E3001B
}

.policy [type="radio"]:not(:checked) + label:before {
  border: 2px solid #C8CDD0;
}

.policy [type="radio"]:checked + label:before,
.policy [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background: #fff;
  top: 50%;
  margin-top: -11px;
}


.policy [type="radio"]:checked + label:after,
.policy [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #E3001B;
  position: absolute;
  top: 50%;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  margin-top: -6px;

}

.policy [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.policy [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.payment-methods.policy [type="radio"]:checked + label:before,
.payment-methods.policy [type="radio"]:not(:checked) + label:before {
  margin-top: 3px;
}

.payment-methods.policy [type="radio"]:checked + label:after,
.payment-methods.policy [type="radio"]:not(:checked) + label:after {
  margin-top: 8px;
}

.payment-methods .invalidField {
  position: absolute !important;
  top: 0;
  margin-top: -17px;
}

.payment-methods [type="radio"]:checked + label.invalidField:before,
.payment-methods [type="radio"]:not(:checked) + label.invalidField:before {
  top: 100%;
  margin-top: 15px;
}

.payment-methods [type="radio"]:checked + label.invalidField:after,
.payment-methods [type="radio"]:not(:checked) + label.invalidField:after {
  top: 100%;
  margin-top: 13px;
}

.payment-methods [type="radio"]:checked + label.invalidField.valid:before,
.payment-methods [type="radio"]:not(:checked) + label.invalidField.valid:before {
  top: 100%;
  margin-top: 34px;
}

.payment-methods [type="radio"]:checked + label.invalidField.valid:after,
.payment-methods [type="radio"]:not(:checked) + label.invalidField.valid:after {
  top: 100%;
  margin-top: 39px;
}

.payment-methods label.acc-terms {
  padding-left: 30px;
}

.payment-methods label.pay-lab {
  padding-top: 25px;
  margin-top: -25px;
  position: relative;
  z-index: 100;
  padding-left: 28px;
  min-width: 240px;
}


.policy .form-check {
  display: inline-block;
  padding-left: 0;
  margin-right: 35px;
  margin-top: 6px;
}

.apply-credit .policy .form-group input[type=radio] {
  position: absolute;
  top: 7px;
  left: 5px;
}

.policy label img {
  margin: 0 7px;
}


.checkmark {
  position: absolute;
  height: 22px;
  width: 22px;
  background-color: white;
  border-radius: 4px;
  border: 0.5px solid #B4B5B5;
  top: -2px;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  appearance: none;
  -webkit-border-image: none;
  -webkit-appearance: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
  -webkit-appearance: none;
  appearance: none;

}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 10px;
  border: solid #163746;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-appearance: none;
  appearance: none;
}


form .form-group.checkbox {
  text-align: left;
  display: flex;
  justify-content: space-between;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}


form .form-group.checkbox a {
  color: #747687;
  text-decoration: underline;

}

form .form-group.checkbox input {
  position: absolute;
  left: 0;
  top: 5px;
  margin-left: 5px;
  z-index: -2;
  /*-webkit-appearance: inherit ;*/
  /*appearance: inherit;*/
  /*-webkit-transform: none;*/

}

form .form-group.checkbox label {
  color: #5B5A63;
  font-size: 13px;
  line-height: 19px;
  display: inline-block;
  padding-left: 30px;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font-weight: 700;
}

form .form-group.checkbox.wrong-address {
  margin-bottom: 22px;
}

form .form-group.checkbox.wrong-address label {
  color: #3F3E4B;
  font-weight: 600;
  font-size: 15px;
}

form a.more-information {
  display: block;
  color: #706F74;
  text-decoration: underline;
  margin: 15px 0 30px 0;
  font-family: 'Montserrat', sans-serif;
}

form a.more-information:hover {
  color: #E3001B;
}

form a.button {
  line-height: 25px;
  width: 100%;
  text-align: center;
}


span.currency {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -31px;
  margin-right: 16px;
  display: block;
  width: 62px;
  border-left: 1px solid #818D92;
  height: 51px;
  background: #F1F5F6;
  padding: 14px 0 10px 17px;
  border-radius: 0 43px 42px 0;
  color: #706F74;
}

form .form-group #inputPrice {
  padding-right: 85px;
}


.apply-credit.final h5 {
  margin-bottom: 0;
}

.apply-credit.final span.person-info {
  color: #E3001B;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  display: block;

}


.apply-credit.final a.call {
  margin-top: 50px;
}

.apply-credit.final .recaptcha {
  padding: 20px 0;
}

.apply-credit.final .instructions {
  color: #3F3E4B;
  font-weight: 500;
  font-size: 17px;
  font-style: italic;
}


/*blog*/

.blog {
  background-color: #F1F5F6;
  position: relative;
  z-index: 10;
}

.blog h1 {
  margin: 0 0 60px 0;
}

/*.blog:after {*/
/*    content: "";*/
/*    background: url("../images/shape2.png") center right fixed no-repeat;*/
/*    background-size: 63%;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*    z-index: -1;*/
/*}*/

.blog .image-ads {
  border-radius: 12px 0 0 12px;
  height: 100%;
  width: 100%;
}

.blog .up-to-date-info {
  background: white;
  height: 100%;
  padding: 95px 80px 30px 43px;
  border-radius: 0 12px 12px 0;
}

.blog .up-to-date-info .short-social {
  margin: 15px auto 25px;
  display: table;
}

.blog .up-to-date-info .short-social a {
  color: #fff;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #97979E;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
}

.blog .up-to-date-info .short-social a:hover {
  background: #E3001B;
}

.blog .up-to-date-info p {
  color: #3F3E4B;
  font-weight: 700;
  font-size: 23px;
}

.blog .up-to-date-info h2 {
  color: #3F3E4B;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
}

.blog .up-to-date-info h2 a {
  display: block;
  color: #3F3E4B;
}

.blog .up-to-date-info h2 a:hover {
  color: #E3001B;
}

.see-more {
  text-align: center;
}

.see-more a img {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 3px;
}

.see-more a .arrow-white {
  display: none !important;
}

.see-more a:hover img.arrow-white {
  display: inline-block !important;

}

.see-more a:hover img.arrow-red {
  display: none !important;
}

.see-more a:hover {
  background: #E3001B;
  color: white;
}


.see-all a .arrow-white {
  display: none !important;
}

.see-all a:hover img.arrow-white {
  display: inline-block !important;

}

.see-all a:hover img.arrow-red {
  display: none !important;
}

.owl-carousel .owl-item img {
  width: auto;
  display: inline-block;
}


.blog article {
  margin-top: 40px;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.blog article img {
  border-radius: 15px 15px 0 0;
  transition: transform .4s;
  margin-bottom: 2px;
}

.blog article img:hover {
  transform: scale(1.1);
}

.blog article .article-info {
  background: white;
  padding: 30px 51px 37px 53px;
  border-radius: 0 0 15px 15px;
}

.blog article .article-info p {
  font-weight: 700;
  color: #3F3E4B;
  font-size: 18px;
  text-align: center;
  min-height: 135px;
}

.blog article .article-info h3,
.blog article .article-info h3 a {
  font-weight: 700;
  color: #3F3E4B;
  font-size: 18px;
  text-align: center;
  min-height: 135px;
  margin-bottom: 20px;
}

.blog article .article-info h3 a:hover {
  color: #E3001B;
}

ul.page-items {
  text-align: center;
  padding: 50px 0 0 0;
}

ul.page-items li {
  display: inline-block;
  padding: 0 5px;
}


ul.page-items li a img {
  width: 16px;
  margin-top: 1px;
}

ul.page-items li a img.red-arrow {
  display: none;
}

ul.page-items .page-link:hover img.gray-arrow {
  display: none;
}

ul.page-items .page-link:hover img.red-arrow {
  display: inline-block;
}

ul.page-items .page-item .page-link {
  position: relative;
  display: block;
  margin-left: -1px;
  padding: 10px 10px 10px 10px;
  line-height: 1;
  color: #B4B4B4;
  background-color: #fff;
  border: 1px solid #BBBBBB;
  width: 40px;
  height: 40px;
  font-weight: 600;
  border-radius: 6px !important;
  font-size: 16px;
}

ul.page-items .page-item.active .page-link {
  z-index: 3;
  color: #E3001B;
  background-color: white;
  border: 2px solid #E3001B
}

ul.page-items .page-item.active .page-link:hover {
  color: #E3001B;
  background-color: white;
}

ul.page-items .page-link:hover {
  color: #E3001B;
  background-color: white;
  border: 1px solid #E3001B;
}


.owl-carousel .owl-stage-outer:after {
  display: none;
}


/*faq-nav*/

.faq-nav {

}

.faq-nav a {
  display: block;
  position: relative;
  margin-bottom: 30px;
}

.faq-nav a img {

}

.faq-nav a .viva-info {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  color: #E50019;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-top: -26px;
  width: 130px;
  transition: all .2s;
}

.faq-nav a .image-bgr-red {
  position: absolute;
  top: 0;
  right: 0;
  width: 214px;
  margin-right: 11px;
  margin-top: 10px;
  display: none;
}

.faq-nav a:hover .viva-info {
  color: #fff;
  margin-top: -20px;
  margin-left: -60px;
  font-size: 16px;
}

.faq-nav a:hover .image-bgr-red {
  display: block;
}

.faq-nav a .arrow {
  width: 21px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 88px;
  margin-bottom: 13px;
  display: none;
  transition: all .4s;
}

.faq-nav a:hover .arrow {
  display: block;
}


.contact-info {
  text-align: center;
  margin-top: 45px;
}

.contact-info p {
  color: #1E1D29;
  font-weight: 600;
  font-size: 22px;
}

.contact-info .phone-info {
  display: inline-block;
  margin-right: 40px;
}

.contact-info .phone-info img {
  width: 30px;
  vertical-align: sub;
  margin-right: 8px;
}

.contact-info .phone-info a {
  display: inline-block;
  margin: 0;
  color: #E3001B;
  font-weight: 700;
  font-size: 29px;
  line-height: 38px;
}

.contact-info .phone-info span {
  display: block;
  color: #706F74;
  font-size: 13px;
  text-align: right;
}

.contact-info .message-info {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
}

.contact-info .message-info img {
  width: 33px;
  vertical-align: bottom;
  margin-right: 8px;
}

.contact-info .message-info a {
  display: inline-block;
  margin: 0;
  color: #3D3C47;
  font-weight: 600;
  font-size: 20px;
}


/*faq general*/

.general-information {

}

.general-information h3 {
  margin: 0 0 30px 0;
}

.general-information h1 {
  color: #1E1D29;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.5;
  margin-bottom: 45px;
}

.faq .accordion-item {
  border: 1px solid #B4B5B5;
  position: relative;
  padding: 0 20px;
  margin: 0 0 18px 0;
  border-radius: 12px;
  cursor: pointer;
}

.faq .question {
  background: transparent;
  color: #3F3E4B;
  font-weight: 600;
  padding: 16px 45px 16px 54px;
  margin-bottom: 0;
  position: relative;
  line-height: 24px;
  font-size: 18px;
}

.faq .accordion-item .answer-content {
  padding: 0 30px 0 54px;
}

.faq .accordion-item .answer-content p {
  font-size: 16px;
  margin-bottom: 17px;
  color: #3A3636;
  line-height: 25px;
}

.faq .accordion-item .question:after {
  font-family: 'FontAwesome';
  content: "\f106";
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -12px;
  font-size: 24px;
}

.faq .accordion-item .question.collapsed:after {
  content: "\f107";
}

.general-information .accordion-item .question-logo {
  width: 38px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -17px;
}


/*thank-you page*/


.success-app {
  position: relative;
}

.success-app:after {
  content: "";
  background: url("../images/mask-gr.png") center left no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-position-y: -74px;
}

.success-app h3 {
  margin: 25px 0;
}

.success-app p {
  text-align: center;
  color: #E50019;
  font-weight: 600;
  font-size: 33px;
  margin-bottom: 0;
}

.success-app .logo-success {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.success-app .logo-success img {
  width: 144px;
}


.success-app .wrapper {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  position: absolute;
  /* margin: 12px; */
  height: 185px;
  width: 185px;
  background-color: transparent;
  border-radius: 50%;
  top: 0;
  left: 50%;
  margin-left: -86px;
  margin-top: -15px;
}

.circle:before {
  position: absolute;
  content: '';
  height: calc(100% + 10px);
  width: calc(100% + 10px);
  border: 4px dashed #E50019;
  top: -13px;
  left: -13px;
  border-radius: inherit;
  animation: spin 7s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}


/*what-next*/


.what-next {
  background: #F1F5F6;
  position: relative;
}

.what-next h3 {
  margin: 0 0 20px 0;
}

.what-next p {
  color: #3A3636;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  margin: 0 0 70px 0;

}

.what-next .contact-inf {
  text-align: center;
}

.what-next .contact-inf img {
  width: 60px;
  margin-bottom: 12px;
}

.what-next .contact-inf p {
  color: #1E1D29;
  font-weight: 600;
  margin-bottom: 0;
  padding: 0 50px;
}

.what-next .contact-inf ul {

}

.what-next .contact-inf ul li {
  margin: 0 5px;
}

.what-next .contact-inf ul li a {

}

.what-next .contact-inf .short-social {
  margin: 15px auto 0;
}

.what-next .contact-inf .short-social li a {
  width: 30px;
  height: 30px;
  line-height: 27px;
}

.what-next .contact-inf .short-social li a i {
  font-size: 14px;
}

.what-next .contact-inf .short-social li a img {
  width: 100%;
  margin: 0;
}


/*contact*/


.intro-contact {
  position: relative;
  //background: url("../images/contact-intro-bgr.jpg") no-repeat !important;
  min-height: 585px;
  background-size: cover !important;
  z-index: 1;
}

.intro-contact h1 {
  font-size: 60px;
  line-height: 60px;
  font-weight: bold;
  color: white;
  margin-top: 380px;
  min-height: 40px;
}


.short-contact.second-block {
  background: white;
  padding-bottom: 100px;
}

.short-contact.second-block:after {
  display: none;
}

.short-contact.second-block .contact-articles {
  position: relative;
  z-index: 10;
  margin-top: -112px;
}

.short-contact.second-block .info-block.find-office {
  background-size: cover;
  margin-top: 35px;
}


.short-contact.second-block .info-block .val {
  min-height: auto;
}

.short-contact.second-block .info-block .location-office {
  margin-top: 18px;
}

.short-contact.second-block .info-block .location-office span {
  color: white;
  font-weight: 500;
  display: block;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

.short-contact.second-block .info-block.email {
  margin-bottom: 25px;
}

.short-contact.second-block .info-block.online-apply {
  background: url("../images/icons/shape-contact.png") center center no-repeat, #AC0409;
  background-size: cover;
  margin-top: 65px;
}

.short-contact.second-block .online-apply {

}

.short-contact.second-block .online-apply .work-days {
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 18px 0;
}

.short-contact.second-block .online-apply .work-days span {
  color: white;
  font-weight: 500;
  display: block;
  font-size: 19px;
  text-align: center;
  line-height: 29px;

}

.short-contact.second-block .online-apply .weekend-days {
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
}

.short-contact.second-block .online-apply .weekend-days span {

}


.short-contact.second-block .info-block.online-apply .eclipse {
  display: inline-block;
  background: white;
  border-radius: 100px;
  padding: 11px 10px 10px 10px;
  width: 68px;
  height: 68px;
  margin-bottom: 15px;
}

.short-contact.second-block .info-block.online-apply .eclipse img {
  width: 46px;
  height: auto;
}


/*contact-with-us*/

.contact-with-us {
  position: relative;
  background: #F1F5F6;
}

.contact-with-us h3 {

}

.contact-with-us form {

}

.contact-with-us form button.button {
  float: right;
}


form .form-group textarea.form-control {
  resize: none;
  height: 165px;
  border-radius: 16px;
}

.form-group textarea::placeholder {

}


/*google-map*/

.google-map iframe {
  width: 100%;
}

.mapouter {
  width: 100%;
  position: relative;
  text-align: right;
  height: 500px;
}

.gmap_canvas {
  width: 100%;
  overflow: hidden;
  background: none !important;
  height: 500px;
}

.gmap_canvas iframe {
  width: 100%;
}

.google-map form .form-group .form-control {
  border: none;
}


/*contact-blogs*/
.contact-blogs .address-and-time {
  position: relative;
  padding-left: 64px;
  height: 100%;
  margin-bottom: 45px;
  padding-top: 33px;
}

.contact-blogs .address-and-time span.open {
  display: none;
}

.contact-blogs .address-and-time.active span.open {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  background: #03DE7F;
  color: white;
  text-transform: uppercase;
  padding: 3px 15px;
  font-weight: 600;
  border-radius: 3px;
  letter-spacing: 1px;
  font-size: 10px;
  margin-left: 63px;
  margin-top: 5px;
}


.contact-blogs .address-and-time img {
  position: absolute;
  left: 0;
  width: 52px;
}

.contact-blogs .address-and-time h4 {
  font-size: 16px;
  line-height: 1.5;
  color: #3D3C47;
  font-weight: bold;
}

.contact-blogs .address-and-time .contact-info {
  margin-top: 15px;
  color: #191919;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  text-align: left;
}

.contact-blogs .address-and-time .contact-info img {
  width: auto;
  position: static;
  vertical-align: sub;
  margin-right: 2px;
}

.contact-blogs .address-and-time .contact-info span {
  display: block;
  font-weight: 400;
  margin: 5px 0 0 0;
}

.contact-blogs .address-and-time a {
  display: block;
  color: #706F74;
  text-decoration: underline;
  font-size: 15px;
  margin-top: 15px;
}

.contact-blogs .address-and-time a:hover {
  color: #E50019;
}


.google-map {
  position: relative;
}

.google-map .how-to-find {
  background: url("../images/mask-gr-contact.png") center right no-repeat, #E31E24;
  width: 755px;
  /* background: #E31E24; */
  padding: 22px 20px 30px 20px;
  border-radius: 18px;
  position: absolute;
  right: 50%;
  z-index: 1;
  margin-right: -375px;
  top: 0;
  margin-top: -75px;
}

.google-map .how-to-find h5 {
  color: white;
  font-weight: 700;
  font-size: 19px;
  margin: 0 0 20px 0;
}

.google-map .how-to-find .form-group {
  margin-bottom: 15px;
  display: inline-block;
}

.google-map .how-to-find .form-group:first-of-type {
  margin-right: 10px;
}

.google-map .how-to-find .form-group:first-of-type .select2-container {
  width: 200px !important;
}

.google-map .how-to-find .form-group:last-of-type .select2-container {
  width: 500px !important;
}

/*selected-contact-info*/

.selected-contact-info {
  position: relative;
}

.selected-contact-info .selected {
  border-radius: 18px;
}

.selected-contact-info h1 {
  color: #1E1D29;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  margin: 25px 0 40px 0;
}

.selected-contact-info:after {
  content: "";
  //background: url("../images/mask-gr-2.png") bottom left no-repeat;
  background: url("../images/service-shape.png") bottom left no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  background-position-y: 0;
  background-size: cover;
}

.selected-contact-info h5 span {
  display: block;
}

.selected-contact-info p {
  margin: 75px 0 0 0;
}

.selected-contact-info .contact-info {
  color: #191919;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  margin: 0 0 30px 0;
}


.selected-contact-info .contact-info img {
  vertical-align: top;
  width: auto;
  margin: 0;
}

.selected-contact-info .contact-info span {
  display: block;
  color: #3A3636;
  font-weight: 400;
}

.selected-contact-info .phone span {
  color: #313133;
  font-weight: 500;
  font-size: 11px;
}

.gmap_canvas iframe {
  width: 100%;
  height: 100%;
}

.google-map form .form-group .form-control {
  border: none;
}

.google-map.second .mapouter {
  height: 700px;
}

.google-map.second .gmap_canvas {
  height: 700px;
}


/*careers*/

.sec-intro {
  min-height: 585px;
}

.careers {
  background: url("../images/career-mask.png") center no-repeat, url("../images/career-mask-2.png") center no-repeat, url("../images/career-bgr.webp") center no-repeat;
  background-size: cover;
}

.text {
  margin: 0;
  padding: 360px 0 40px 0;
  position: static;
}

.text h1 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 60px;
  margin-bottom: 20px;
}

.text p {
  color: white;
  font-size: 21px;
}


/*viva-credit-employer*/

.viva-credit-employer {
  position: relative;
}

.viva-credit-employer:after {
  position: absolute;
  content: "";
  background: url("../images/career-shape.png") right center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.viva-credit-employer h3 {
  margin: 0 0 20px 0;
}

.viva-credit-employer p {
  color: #3A3636;
  text-align: center;
  font-size: 17px;
  margin: 0 0 65px 0;
  line-height: 28px;

}

.viva-credit-employer .video-image {
  margin: 0 0 90px 0;
  position: relative;
}

//.viva-credit-employer .video-image:after {
//  position: absolute;
//  content: "";
//  background: url("../images/play-button.png") center no-repeat;
//  background-size: 100%;
//  width: 140px;
//  height: 140px;
//  left: 50%;
//  top: 50%;
//  margin-top: -70px;
//  margin-left: -70px;
//  cursor: pointer;
//}
//
//
//.viva-credit-employer .video-image:after:hover {
//  opacity: 0.7;
//}

.viva-credit-employer .awards {
  max-height: 70px;
}


/*viva-family*/

.viva-family {
  background: url("../images/get-money-bgr.jpg") center center no-repeat;
  background-size: cover;
  text-align: center;
}

.viva-family .heading {
  color: #fff;
  margin: 0 100px 28px;
}

.viva-family p {
  color: #fff;
  font-size: 20px;
  margin: 0 0 75px 0;
  font-weight: normal;
}

.viva-family img {
  height: 80px;
  margin: 0 0 15px 0;
}

.viva-family .lab {
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  padding: 0 30px;
  font-weight: 600;
}


/*free-position*/
.free-position {
  background: #F1F5F6;
}

.free-position .heading {
  margin: 0 0 50px 0;
}

.free-position .positions-block {
  background: white;
  margin: 0 0 18px 0;
  padding: 18px 26px;
  border-radius: 16px;
}

.free-position h5,
.free-position .address,
.free-position .time,
.free-position .see-more {
  display: flex;
  align-items: center;
  height: 100%;
}

.free-position h5 {
  color: #3F3E4B;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
}

.free-position .see-all a img {
  margin-bottom: 2px;
}

.address {
  font-weight: 600;
  color: #191919;
  font-size: 16px;
}

.address img {
  margin: 0 5px 3px 0;
  width: 14px;
}

.time {
  color: #313133;
  font-weight: 500;
  font-size: 16px;
}

.time img {
  margin: 0 5px 1px 0;
  width: 14px;
}

.free-position .see-more {
  justify-content: end;
}

.free-position .see-all {
  text-align: center;
  margin: 30px 0 0 0;
}


/*apply-for-work*/

.apply-for-work {

}

.apply-for-work h2 {
  margin: 0 0 35px 0;
}

.apply-for-work p {
  color: #313133;
  margin-bottom: 30px;
}

.apply-for-work p a {
  text-decoration: underline;
  color: #313133;
  display: table;
  font-weight: 500;
}

.apply-for-work p a:hover {
  color: #E3001B;
}


.up-to-date {
  background: #F1F5F6;
}

.blog.up-to-date:after {
  display: none;
}

.up-to-date article {
  margin: 0;
}


/*free-position*/

.free-position-intro {
  background: url("../images/free-position-mask.png") center no-repeat, url("../images/free-position-mask2.png") center no-repeat, url("../images/free-position-bgr.png") center no-repeat;
  background-size: cover;
}


/*customer-service*/


.customer-service {
  position: relative;
}

.customer-service h1 {
  text-align: left;
  margin-bottom: 30px;
}

.customer-service:after {
  content: "";
  position: absolute;
  background: url("../images/service-shape.png") left center no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.customer-service.heading {
  text-align: left;
  margin: 0 0 30px 0;
}

.customer-service h5 {
  color: #1E1D29;
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 15px 0;
  line-height: 28px;
}

.customer-service p {
  margin-bottom: 40px;
}

.specialist-location-and-apply-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
  flex-wrap: wrap;
}

.specialist-location-and-apply-btn .specialist-loc {

}

.specialist-location-and-apply-btn .specialist-loc .address {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 15px;
}

.specialist-location-and-apply-btn .specialist-loc .time {
  display: inline-block;
  margin-bottom: 15px;
}

.specialist-location-and-apply-btn .apply-btn {
  margin-bottom: 15px;
}

.specialist-location-and-apply-btn .apply-btn a:hover {
  background: #FC0A27;
  color: white;
}

.customer-service ul {

}

.customer-service ul li {
  color: #313133;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 20px;
  position: relative;
  padding-left: 12px;
}

.customer-service ul li:before {
  content: "";
  position: absolute;
  left: 0;
  background: black;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  top: 0;
  margin-top: 11px;
}


/*apply*/

.apply {
  background: #F1F5F6;
}

.apply form {

}

.apply a.button {
  float: right;
  width: auto;
}

.apply button.button {
  float: right;
  width: auto;
}

.apply span.currency {
  margin-top: -43px;
  width: auto;
  background: #F1F5F6;
  padding: 16px 18px 10px 17px;
  color: #3A3636;
  font-weight: 600;
  text-transform: uppercase;
  display: none;
}

.apply form .form-group #inputPrice {
  padding-right: 120px;
  position: relative;

}

.apply ::-webkit-file-upload-button {
  display: none;
}

.apply .form-group {
  position: relative;
}

.apply label[for=inputPrice] {
  background: #F1F5F6;
  position: absolute;
  right: 0;
  padding: 15px 20px 15px 17px;
  top: 50%;
  margin-top: -25px;
  height: auto;
  color: #3A3636;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  border-left: 1px solid #818D92;
  border-radius: 0 43px 42px 0;
  margin-right: 2px;
  cursor: pointer;
  z-index: 1;
}

/*.apply form input[type="file"]{*/
/*    color: transparent;*/
/*}*/


.apply form input[type=file]::before {
  content: "CV";
  position: absolute;
  color: #706F74;
  font-size: 14px;
  margin-right: 10px;
}


// NEW FILE INPUT TEST

.it .btn-orange {
  background-color: blue;
  border-color: #777 !important;
  color: #777;
  text-align: left;
  width: 100%;
}

.it input.form-control {

  border: none;
  margin-bottom: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
}

.it .form-control:focus {
  border-color: #ff4d0d;
  box-shadow: none;
  outline: none;
}

.fileUpload {
  overflow: hidden;
  display: inline-block;
  height: 35px;
  line-height: 37px;
  position: absolute;
  right: 0;
  border-left: 1px solid #818d92;
  margin-right: 1px;
  color: #3a3636;
  padding: 0 20px;
  top: 56%;
  border-radius: 0 43px 43px 0;
  margin-top: -20px;
  background: #f1f5f6;
}

.file-upload-text {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  background-color: white !important;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}


// END

.file-upload {
  display: block;
  color: #3A3636;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
}

.file-upload .file-select {
  border: 1px solid #818D92;
  border-radius: 30px;
  padding: 6px 17px 5px 17px;
  font-size: 14px;
  height: auto;
  color: #706F74;
  background: white;
}

.file-upload .file-select .file-select-button {
  display: inline-block;
  height: 51px;
  line-height: 50px;
  position: absolute;
  right: 0;
  border-left: 1px solid #818D92;
  margin-right: 1px;
  color: #3A3636;
  padding: 0 20px;
  top: 50%;
  border-radius: 0 43px 43px 0;
  margin-top: -26px;
  background: #F1F5F6;
}

.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  font-weight: 400;
}

.file-upload .file-select:hover {
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload .file-select:hover .file-select-button {
  opacity: 0.7;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select {
  border-color: #3fa46a;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload.active .file-select .file-select-button {
  background: #E3001B;
  color: #FFFFFF;
  opacity: 1;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}

.file-upload .file-select input[type=file] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}

.file-upload .file-select.file-select-disabled:hover {
  display: block;
  border: 2px solid #dce4ec;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;

}


/*call-to-action*/

.call-to-action {
  background: #00ACC0;
  padding: 11px 0;
  position: fixed;
  width: 100%;
  z-index: 9000;
  transition: all .6s;
  top: 0;
  transform: translateY(-198%);
}

.call-to-action.show {
  transform: translateY(0);
  margin-top: 83px;
}

.call-to-action h5 {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 23px;
  line-height: 35px;
  margin: 0 0 5px 0;
}

.call-to-action span {
  color: #FFFFFF;
  font-size: 16px;
  margin-right: 15px;
}

.call-to-action a.learn-more {
  color: #FFFFFF;
  text-decoration: underline;
  font-size: 16px;
}

.call-to-action a.learn-more:hover {
  opacity: 0.7;
}

.call-to-action .set-price {
  display: flex;
  align-items: center;
  background: #ECF1F3;
  border-radius: 35px;
  justify-content: space-between;
  padding: 7px 11px;
}

.call-to-action .set-price button:first-child {
  padding: 0 0 1px 0;
}

.call-to-action .set-price button {
  background: #E3001B;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  font-size: 23px;
  font-weight: 600;
}

.call-to-action .set-price button:hover {
  background: #cf041d;
}

.call-to-action .set-price span {
  color: #3F3E4B;
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}

.call-to-action a.button {
  margin: 0;
  padding: 16px 50px 15.5px 50px;
}


.left-fragment {
  position: relative;
}

.left-fragment:after {
  content: "";
  background: url(../images/left-fragment.svg) top left no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -8%;
  z-index: -1;
}

.right-fragment {
  position: relative;
}

.right-fragment:after {
  content: "";
  background: url(../images/right-fragment.svg) top right no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}


.data-progress {
  position: relative;
  //background: white;
  padding: 47px 30px 14px 30px;
  border-radius: 12px;
  //box-shadow: 0 6px 9px 4px rgba(128, 127, 128, 0.11);
}

.data-progress .line-01.active,
.data-progress .line-02.active {
  background-color: #E3001B;
}

.data-progress .line-01 {
  height: 3px;
  background: #C8CDD0;
  width: 33%;
  position: absolute;
  top: 45px;
  left: 14%;
}

.data-progress .line-02 {
  height: 3px;
  background: #C8CDD0;
  width: 33%;
  position: absolute;
  top: 45px;
  right: 14%;
}

.data-progress .progress-step.active .val {
  color: #E3001B;
}

.data-progress .progress-step.completed .val span {
  opacity: 0;
}

.data-progress .progress-step.completed .val:after {
  content: "";
  background: url(../images/icons/border-check.svg) no-repeat;
  background-size: 100%;
}

.data-progress .progress-step.active .val:before {
  content: "";
  background: url(../images/icons/red-border.png) no-repeat;
  background-size: 100%;
  z-index: 2;
  width: 52px;
  height: 56px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -26px;
  margin-top: -26px;
}

.data-progress .progress-step .val {
  color: #BCBCBC;
  font-weight: 600;
  font-size: 18px;
  position: relative;
}

.data-progress .progress-step .val span {
  position: relative;
  top: -12px;
  z-index: 2;
}

.data-progress .progress-step .val:after {
  content: "";
  background: url(../images/icons/border.png) no-repeat;
  background-size: 100%;
  width: 52px;
  height: 56px;
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -26px;
  margin-top: -26px;
}

.data-progress .progress-step .lab {
  color: #3F3E4B;
  font-weight: 600;
  font-size: 16px;
}

.data-progress .progress-step.step-01 {
  float: left;
  text-align: center;
}

.data-progress .progress-step.step-02 {
  text-align: center;
}

.data-progress .progress-step.step-03 {
  float: right;
  text-align: center;
}


ul.payment-methods.policy {
  position: relative;
  min-width: 870px;
  padding-top: 10px;
}

ul.payment-methods.policy li {
  margin-bottom: 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
}

ul.payment-methods.policy li input[type='radio'] {
  position: absolute;
  top: 4px;
  left: 2px;
  z-index: -1;
}

ul.payment-methods.policy li .checkbox {
  position: relative;
}

ul.payment-methods.policy li .checkbox span.checkmark {
  position: absolute;
  bottom: auto;
  top: 49px;
  margin: 78px 0 0 0;
}

ul.payment-methods.policy li .checkbox #bank_account_info label {
  padding-left: 28px;
}

ul.payment-methods.policy li .checkbox #epay_info,
ul.payment-methods.policy li .checkbox #bank_account_info {
  margin-bottom: 25px;
}

ul.payment-methods.policy li .checkbox input[type="checkbox"] {
  z-index: 1;
  width: 20px;
  position: relative;
  height: 20px;
  top: 27px;
  opacity: 0;
}

.term-and-amount .calculator-holder {

  position: absolute;
  background: white;
  left: 0;
  width: 100%;
  top: 0;
  padding: 125px 23px 27px 23px;
  border-radius: 12px;
  z-index: 0;
  display: none;
  box-shadow: 0 6px 9px 4px rgba(128, 127, 128, 0.11);
}

.term-and-amount .calculator-holder.show {
  display: block;
}

.term-and-amount .calculator-holder:before {
  display: none;
}

.term-and-amount .calculator-holder .calculator {
  position: static;
  width: 100%;
}

.term-and-amount .calculator-holder .calculator .nav-instalments a {
  padding: 0 10px 0 30px;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;
}

.term-and-amount .calculator form {
  padding: 0;
}

.term-and-amount .calculator-holder .calculator .slider-holder .selected-val {
}

.term-and-amount .calculator-holder .calculator .slider-holder {
  padding-bottom: 10px;
}

.term-and-amount .calculator-holder .calculator .price-info {
  display: flex;
  justify-content: space-between;
}

.term-and-amount .calculator-holder .calculator .price-info span {
  font-weight: 400;
  font-size: 14px;
  margin: 7px 0 0 0;
}

.term-and-amount .calculator-holder .calculator .nav-instalments {
  margin: 25px 0 18px 0;
}

.term-and-amount .calculator-holder .irs span {
  font-weight: 400;
  font-size: 14px;
}

.term-and-amount .calculator-holder .irs--round,
.term-and-amount .calculator-holder .irs-line {
  width: 100%;
}

.term-and-amount .count {
  position: relative;
  z-index: 2;
  font-family: 'Montserrat', sans-serif;
}


//blog-article

.blog-article {
  background: white;
}

.blog-article h1 {
  margin-bottom: 25px;
}

.info-blog-article h2 {
  margin-bottom: 25px;
  font-size: 26px;
  line-height: 32px;
  font-weight: normal;
}

.blog-article .back-to-blog {
  text-align: center;
  margin-bottom: 20px;
}

.blog-article .back-to-blog a {
  color: #706F74;
  font-size: 17px;
}

.blog-article .back-to-blog a:hover {
  text-decoration: underline;
}

.blog-article .back-to-blog a img {
  vertical-align: baseline;
  margin-right: 2px;
}

.blog-article .info-blog-article {

}

.blog-article .info-blog-article p {
  margin-bottom: 25px;
}

.blog-article .info-blog-article p:last-child {
  margin: 0;
}

.blog-article .info-blog-article ul li {
  margin-bottom: 10px;
  color: #3A3636;
  font-weight: 500;
}

.blog-article .calendar {
  text-align: center;
  margin-bottom: 32px;
  margin-top: -35px;
}

.blog-article .calendar img {
  margin-right: 2px;
  vertical-align: baseline;
}

.blog-article .calendar span {
  color: #313133;
  font-size: 17px;
}

.blog-article .blog-image {
  margin-bottom: 45px;
  text-align: center;

}

.blog-article .info-blog-article p a {
  font-weight: 600;
}

.blog-article .blog-image img {
  border-radius: 18px;
}


//mobile-menu


.mobile-menu {
  display: block !important;
  width: 100%;
  left: 0;
  height: 100vh;
  background: white;
  z-index: 0;
  text-align: center;
  transition: all .6s;
  padding-top: 50px;
  top: 0;
  position: fixed;
  margin-top: -100vh;
}

body.open .mobile-menu {
  margin-top: 0;
}

.mobile-menu li {
  margin-bottom: 15px;
}

.mobile-menu li a {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #4E4B63;
}

.mobile-menu li a:hover {
  color: #E3001B;
}

.promotions {
  background-color: #FEF2F3;
}

.promotions .promotion-block {
  margin: 0 0 40px 0;
  background-color: #fff;
  border-radius: 18px;
}

.promotions .promotion-block:last-of-type {
  margin: 0;
}

.promotions .promotion-block .promotion-img {
  display: block;
}

.promotions .promotion-block .promotion-img img {
  border-radius: 18px 0 0 18px;
}

.promotions .promotion-block h3, h2 {
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  margin: 0;
  color: #3F3E4B;
  padding: 0 30px;
}

.promotions .promotion-block .promotion-content {
  text-align: center;
}

.promotions .promotion-block .term {
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
  margin: 25px 0 30px 0;
}

.promotions .promotion-block .term img {
  margin-right: 3px;
  height: 15px;
  vertical-align: baseline;
}

.promotions .promotion-block .term span {
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  color: #E3001B;
  display: block;
  margin: 3px 0 0 0;
}

/* -------------------- */

.promotion .internally-nav {
  text-align: center;
}

.promotion .internally-nav img {
  margin-right: 5px;
  vertical-align: baseline;
}

.promotion .internally-nav a {
  font-size: 18px;
  line-height: 22px;
  color: #706F74;
}

.promotion .internally-nav a:hover {
  color: #E50019;
}

.promotion h1 {
  font-size: 40px;
  line-height: 44px;
  color: #1E1D29;
  font-weight: bold;
  margin: 40px 0 40px 0;
  text-align: center;
}

.promotion .promotion-img {
  margin: 0 0 30px 0;
}

.promotion .promotion-img img {
  border-radius: 0 0 18px 18px;
  width: 100%;
}

.promotion .term {
  background-color: #E50019;
  color: #fff;
  border-radius: 18px 18px 0 0;
  padding: 15px 0;
  text-align: center;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
}

.promotion .term img {
  width: 23px;
  border-radius: 0;
  margin: -2px 3px 0 0;
}

.promotion .term span {
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
  margin-left: 5px;
  display: inline-block;
}

.promotion .promotion-content {
  padding: 0 40px;
}

.promotion .promotion-content p {
  margin: 40px 0 30px 0;
}

.promotion .promotion-content .promotion-list {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.promotion .promotion-content .promotion-list li {
  position: relative;
  font-size: 16px;
  padding-left: 30px;
  margin: 0 0 15px 0;
}

.promotion .promotion-content .promotion-list li:before {
  content: "";
  background-size: 18px;
  background: url("../images/icons/list-component.svg") no-repeat;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 1px;
  left: 0;
}

table, tr, td {
  border: 1px solid black;
}

.invalidField {
  color: red !important;
  border-color: red !important;
}

.validField {
  border-color: green !important;
}

.faq .accordion-item .answer-content a span,
.faq .accordion-item .answer-content p span,
.faq .accordion-item .answer-content a {
  color: #da763a !important;
  font-weight: 600;
}


#module_item_50 p strong {
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

#module_item_52 h2 {
  margin-top: 10px;
}

#module_item_53 strong {
  display: inline-block;
  margin-top: 10px;
}

.ck-content a {
  font-weight: 600;
}


#additionalServiceBox {
  margin-left: 37px;
  border-radius: 5px;
  box-shadow: rgb(45, 45, 45, 0.7) 1px 1px 3px;
  z-index: 5;
  line-height: 1.5;
  font-size: 12px;
  bottom: 57px;
}

.tooltip-sec .tooltip-inner {
  right: 0;
  max-width: 220px;
}

.tooltip-inner:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 25px;
  background-color: #E3001B;
  left: -7px;
  width: 20px;
  border-bottom-right-radius: 16px 14px;
}

.tooltip-inner:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 25px;
  left: -26px;
  width: 26px;
  background-color: #F1F5F6;
  border-bottom-right-radius: 10px;
}

//#additionalServiceBox:before {
//  content: "\f0d9";
//  font: normal normal normal 25px/1 FontAwesome;
//  color: #ec1d23;
//  position: absolute;
//  top: -2px;
//  left: -7px;
//}

#additionalServiceBox a {
  color: white
}

.newsletter p.alert.alert-success {
  display: block;
}

.workTime p {
  font-weight: 400;
  font-size: 14px;
}

.subheading {
  font-size: 26px;
  line-height: 22px;
  font-weight: 500;
  text-align: center
}

.thank-you-small-text {
  margin-top: 13px !important;
  text-align: center !important;;
  color: black !important;;
  font-weight: 500 !important;;
  font-size: 16px !important;;
  margin-bottom: 0 !important;
}

.thank-you-content .short-social li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

form[name=application_step_one] select.hide-first-option option:first-child,
form[name=application_step_two] select.hide-first-option option:first-child,
form[name=application_step_three] select.hide-first-option option:first-child {
  display: none;
}

form[name=application_step_one] select,
form[name=application_step_two] select,
form[name=application_step_three] select {
  width: 100% !important;
}

.google-map .js-example-basic-single .select2-container {
  display: block;
}

.google-map .select2-container--default .select2-selection--single {
  padding: 5px 20px 10px 10px;
  height: 38px;
  border-radius: 80px;
}

.google-map .select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.google-map .select2-container--default .select2-selection--single .select2-selection__arrow:after {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  background: url("../images/icons/arrow-select.svg") no-repeat right;
  background-size: 10px;
  width: 12px;
  height: 12px;
  margin-right: 3px;
  margin-top: -1px;
}

.google-map .select2-search__field {
  display: none;
}

.myFont {
  font-size: 13px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #E31E24;
  color: white
}

.google-map .select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.google-map .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-radius: 20px 20px 0 0;
}

.google-map .select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.google-map .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-radius: 0 0 20px 20px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 15px;
  padding-right: 11px;
}

.calculator #nav-home2 {
  margin-top: -22px;
}

.calculator #nav-home2 .invalidField {
  margin-top: 0 !important;
  font-size: 13px !important;
}

.calculator #smallAmountsForm .invalidField {
  margin-top: 0 !important;
  font-size: 11px !important;
}

.calculator .icheck-success label:nth-child(3) {
  margin-top: -38px !important;
  padding-top: 38px;
}

.calculator .icheck-success label.invalidField {
  position: absolute;
  top: -17px;
}

.calculator .icheck-success label.invalidField:before {
  bottom: -20px;
}

.calculator .icheck-success label.invalidField:after {
  top: 19px !important;
}

.intro .calculator .icheck-success label.invalidField:before {
  bottom: 16px;
}

.intro .calculator .icheck-success label.invalidField:after {
  top: -17px !important;
}

.intro .calculator .icheck-success label.invalidField {
  bottom: -13px;
  top: auto;
}

.intro .calculator .icheck-success label:nth-child(3) {
  padding-top: 0;
  margin-top: 3px !important;
}

.apply-credit form .form-group.checkbox #terms-error, #DataUseNOICheckAgreement-error {
  padding-left: 0;
}

section .ck-content div {
  margin-left: 0 !important;
}

section .ck-content table {
  width: 100% !important;
}

section .ck-content table tr td {
  padding: 5px;
}

.service-rating .heading {
  color: #707070;
  text-transform: uppercase;
  font-size: 24px;
  margin: 0 0 40px 0;
  line-height: 36px;
}

.service-rating .rate-question {
  color: #707070;
  font-size: 20px;
}

.service-rating .rate-block .rate-icons {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0 20px 0;
}

.service-rating .rate-block .rate-icons .form-group {
  position: relative;
}

.service-rating .rate-block .rate-icons .form-group:not(:last-child) {
}

.service-rating .rate-block .rate-icons .form-group label {
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: all .5s;
}

.service-rating .rate-block .rate-icons .form-group label.active {
  background: #E9E9ED;
}


.service-rating .rate-block .rate-icons .form-group label img {
  width: 82px;
  height: 82px;
}

.service-rating .rate-block .rate-icons .form-group label input {
  z-index: -1;
  opacity: 0;
}

.service-rating .rate-block .rate-icons .form-group label span {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-top: 8px;
}

.service-rating .rate-block .rate-icons .form-group.red label span {
  color: #ED1C24;
}

.service-rating .rate-block .rate-icons .form-group.yellow label span {
  color: #F5DD08;
}

.service-rating .rate-block .rate-icons .form-group.green label span {
  color: #05A705;
}

.service-rating .rate-block .form-group label {
  color: #707070;
  font-weight: 400;
  font-size: 20px;
}

.service-rating .rate-block .form-group textarea {
  margin-top: 6px;
  font-size: 20px;
}

.service-rating .rate-block form .button {
  border-radius: 30px;
  font-weight: bold;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 18px;
  display: table;
  margin: 0 0 0 auto;
}

.testimonial-slider {
  background: #E3001B;
}

.testimonial-slider .testimonial-block {
  margin-bottom: 25px;
}

.testimonial-slider .testimonial-block p {
  padding: 0 100px;
  margin-top: 20px;
  min-height: 150px;
}

.testimonial-slider .testimonial-block img {
  width: 45px;
}

.testimonial-slider .testimonial-block .customer-info {
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  display: table;
  margin: 35px auto 0 auto;
  border-top: 1px solid #fff;
  padding: 5px 12px;
}

.testimonial-slider .testimonial-block .customer-info span {
  display: block;
}

.testimonial-slider .owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
}

.testimonial-slider .owl-nav {
  display: none;
}

.our-benefits {
  background: #E3001B;
}

.our-benefits .benefit {
  text-align: center;
  color: #fff;
  font-size: 14px;
  padding: 0 25px;
  margin: 30px 0;
}

.our-benefits .benefit .img-block {
  height: 80px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.our-benefits .benefit .img-block img {
  max-height: 80px;
}

.intro-about-us {
  background: url("../images/about-us-bgr.jpg") center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 50px 0;
}

.intro-about-us .text {
  padding: 0 !important;
}

.intro-about-us .text .heading {
  margin-bottom: 6px;
}

.intro-about-us .text p {
  margin: 0;
}

.intro-about-us span {
  font-size: 19px;
}

.intro-about-us h1 br {
  display: none !important;
}

.heading-3 {
  color: #1E1D29;
  font-size: 43px;
  line-height: 52px;
  font-weight: bold;
  margin-bottom: 40px;
}

.who-are-we h2 {
  color: #1E1D29;
  font-size: 43px;
  line-height: 52px;
  font-weight: bold;
  margin-bottom: 40px;
}

.who-are-we p {
  color: #1E1D29;
}

.who-are-we p:last-child {
  margin: 0;
}

.our-values {
  background: url("../images/our-value-bgr.jpg") center no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}

.advantage-section {
  background: url("../images/our-value-bgr.jpg") center no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}

.our-values .value-block {
  position: relative;
  height: 100%;
  padding: 22px 17px 30px 17px;
  max-width: 275px;
  margin: 0 auto;
  margin-top: 15px !important;
}

.our-values .value-block:before {
  content: "";
  background: url("../images/values-path.png") center no-repeat;
  background-size: 96%;
  width: 108%;
  height: 106%;
  position: absolute;
  margin-left: -10px;
  margin-top: -12px;
  left: 0;
  top: 0;
}

.our-values .value-block img {
  height: 80px;
  margin-bottom: 10px;
}

.our-values .value-block h5 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}

.our-values .value-block p {
  position: relative;
  max-width: 275px;
  color: #ffffff;
  font-size: 15px;
  margin: 0 auto;
}

.our-values .col-lg-4:nth-child(2) .value-block {
  margin: 0;
}

.our-values .col-lg-4:last-child .value-block {
  margin: 0 0 0 auto;
}

.our-history p:last-child {
  margin: 0;
}

.come-to-the-team .button {
  font-weight: bold;
}

.characterizes-us {
  background: #FFF2F3;
}

.characterizes-us img {
  height: 180px;
  margin-left: -20px;
}

.characterizes-us .desc {
  font-size: 16px;
  font-weight: 500;
  color: #1E1D29;
  margin-top: -25px;
}


// SURVEY STYLES START


.questionnaire-block {
  padding: 15px 0 45px 0;
  display: none;
}

.questionnaire-block.open {
  display: block;
}

//.apply-credit.final .questionnaire-block h5 {
//  margin-bottom: 15px;
//}

.questionnaire-block .modal-btn,
.close-questionnaire {
  border-radius: 50px;
  display: block;
  margin: 0 0 0 auto;
  background: transparent;
}

.questionnaire-block .modal-btn:hover,
.close-questionnaire:hover {
  background: #E3001B;
}

.questionnaire-block .modal-btn.gray,
.questionnaire-block .modal-btn.gray:focus,
.questionnaire-block .modal-btn.gray:active {
  color: #767A7B;
  border-color: #767A7B;
}

.questionnaire-modal {
  z-index: 10002;
}

.questionnaire-modal .modal-dialog {
  max-width: 100%;
  margin: 58px 50px 0 50px;
}

.questionnaire-modal .modal-dialog .modal-content {
  border-radius: 30px;
  position: relative;
}

.questionnaire-modal .modal-dialog .modal-content:after {
  background: url("../images/path-bgr4.png") center left no-repeat;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 1070px;
  z-index: 0;
}

.questionnaire-modal .alert-danger {
  margin-bottom: 0;
}

.my-slider-progress {
  background: #dee4ec;
  position: relative;
  top: -78px;
  display: block;
  max-width: 550px;
  margin: 0 auto;
  height: 16px;
  border-radius: 30px;
}

.my-slider-progress-bar {
  background: #e3001b;
  height: 16px;
  transition: width 400ms ease;
  width: 0;
  position: relative;
  z-index: 1;
  border-radius: 30px;
  top: 0;
}

.questionnaire-slide h5 {
  font-size: 20px;
  line-height: 29px;
  margin: 20px 0 20px 0;
  font-weight: 600;
  color: #495057;
}

form .questionnaire-slide .splide__slide {
  padding: 0 180px 110px 50px;
  display: none;
}

form .questionnaire-slide .splide__slide.is-active {
  height: 100% !important;
  display: block;
}

.questionnaire-text {
  padding: 25px 25px 0 25px;
}

.questionnaire-text h3 {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
  color: #3A3636;
}

.questionnaire-text h4 {
  font-weight: bold;
  margin-bottom: 0;
  color: #3A3636;
}

.questionnaire-slide .form-check {
  display: inline-block;
  padding: 0;
  margin: 0 30px 20px 0;
  vertical-align: text-top;
}

.questionnaire-slide .form-check label {
  cursor: pointer;
}

.questionnaire-slide .form-check input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.questionnaire-slide .form-check p {
  max-width: 122px;
  margin-bottom: 0;
}

.questionnaire-slide .splide__pagination {
  display: none;
}

.questionnaire-modal .modal-header {
  padding: 0;
  border: none;
}

.questionnaire-modal .modal-header .close {
  margin: -10px 0 10px auto;
  position: relative;
  right: 2rem;
  top: 1rem;
  padding: 0;
  z-index: 10003;
}

.questionnaire-modal .modal-header .close span {
  font-size: 36px;
}

.questionnaire-modal .modal-body {
  padding-bottom: 0;
  padding-top: 0;
  margin-top: -30px;
  z-index: 2;
}

.questionnaire-slide .form-check img {
  width: 122px;
  height: 126px;
  border-radius: 18px;
  border: 4px solid #C8CDD0;
}

.questionnaire-slide .form-check label.active img {
  border-color: #00ACC0;
}

.checkbox-item {
  position: absolute;
  width: 42px;
  height: 42px;
  background: #00ACC0;
  top: 0;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 100px;
  display: none;
  margin-top: 73px;
}

.questionnaire-slide .form-check label.active .checkbox-item {
  display: block;
}

.checkbox-item:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 13px;
  height: 23px;
  border: solid #fff;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
  -webkit-appearance: none;
  appearance: none;
  margin-left: -7px;
  margin-top: -14px;
}

.questionnaire-slide .nextBtn,
.questionnaire-slide .prevBtn {
  position: absolute;
  top: auto;
  bottom: 0;
  margin-bottom: 30px;
  border: none;
  background: transparent;
  font-weight: 600;
  text-transform: uppercase;
  color: #e3001b;
}

.questionnaire-slide .nextBtn.disabled {
  display: none;
}

.questionnaire-slide .nextBtn:hover,
.questionnaire-slide .prevBtn:hover {
  opacity: 0.7;
}

.questionnaire-slide .nextBtn {
  right: 50%;
  margin-right: -190px;
}

.questionnaire-slide .prevBtn {
  left: 50%;
  margin-left: -190px;
}

.questionnaire-slide .prevBtn img,
.questionnaire-slide .nextBtn img {
  display: inline-block;
  border-radius: 100%;
  padding: 7px;
}

.close-questionnaire {
  border-radius: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 10px;
  z-index: 10056;
  margin-left: 0;
  padding: 7px 14px;
}

.arrow-disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}

.disabledBtn {
  background: lightgrey;
  opacity: 0.4;
  pointer-events: none;
}

.questionnaire-text {
  padding: 25px 25px 0 25px;
}

.questionnaire-text h3 {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
  color: #3A3636;
}

.questionnaire-text h4 {
  font-weight: bold;
  margin-bottom: 0;
  color: #3A3636;
}

#surveyInfoText {
  position: absolute;
  top: 120px;
  left: 95%;
  width: 412px;
  background-color: #ec1d23;
  padding: 5px 10px;
  margin-left: 15px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px #2d2d01;
  z-index: 5;
  line-height: 1.5;
  font-size: 12px;
}

#surveyInfoText a {
  color: white;
}

.tooltip-inner {
  max-width: 410px;
  padding: 7px 12px 10px 12px;
  line-height: 21px;
  background-color: #E3001B;
  box-shadow: 1px 1px 3px #2d2d01;
  border-radius: 13px;
  margin-left: 10px;
  position: absolute;
  width: 410px;
  bottom: 4px;
}

.questionnaire-block .questionnaire-head-info {
  position: static !important;

  i {
    position: relative;
    z-index: 10000;
  }
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  display: none;
}

// SURVEY STYLES END

//CARD PAGE

.sec-intro.intro-card {
  background-position: center !important;

  .text {
    padding: 380px 0 20px 0;

    h1 {
      font-size: 45px;
      line-height: 54px;
      padding-right: 100px;
    }
  }
}

.viva-white .way-info {
  margin-bottom: 45px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .small-heading {
    margin-bottom: 30px;
  }
}

.faq-cont {
  background: #ED1C24;
  padding: 60px 0 80px 0;

  .accordion-item {
    background: #fff;
    margin: 0 0 35px 0;
  }

  .see-all {
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.faq-products {
  padding: 60px 0 27px 0 !important;
}

.video-slider {
  background: #E3001B;

  .owl-theme {
    .owl-nav {
      button {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;

        &:hover {
          background: transparent;
        }

        &.owl-prev span {
          padding-right: 3px;
        }
      }
    }

    .owl-dots {
      display: none;
    }
  }

  .video-btn {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
    border: none;
  }
}

.video-modal {
  .modal-dialog {
    margin-top: 110px;
    max-width: 950px;

    .modal-content {
      border-radius: 19px;
    }
  }

}

.iframe-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 78%;
  cursor: pointer;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 19px;
    pointer-events: none;

  }
}

.viva-smart-for {
  position: relative;

  &:after {
    content: "";
    background: url("../images/path-bgr3.png") 0 fixed no-repeat;
    background-size: 85%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.why-viva-smart {
  background: url("../images/viva-smart-bgr.jpg") center no-repeat;
  background-size: cover;

  h5 {
    font-size: 16px;
    line-height: 21px;

    &.fw-medium {
      font-weight: 500;
    }
  }

  img {
    height: 145px;
  }

  p {
    font-weight: 400;
  }
}


.smart-example {
  .small-heading {
    font-size: 28px;
    line-height: 37px;
  }
}

#career-contact-section .form-error-message {
  color: white;
}

#career-contact-section .form-error-icon {
  color: #dc3545;
  background-color: white;
}

.promo-hero {
  background-size: cover !important;
  background-repeat: no-repeat;
  min-height: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .form-block {
    margin-right: -190px;
  }

  form {
    background: #fff;
    border-radius: 44px;
    padding: 25px 30px;

    h4 {
      color: #1E1D29;
      font-size: 26px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 30px;
    }

    label {
      color: #1E1D29;
      font-size: 15px;
      margin-bottom: 8px;
    }

    .form-control {
      border: 2px solid #879297;
      padding: 16px 22px;
    }

    .form-check {
      label {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

}

.how-to-participate {
  .participate-block {
    padding: 0 12px;

    img {
      max-width: 160px;
      margin-bottom: 15px;
    }

    h5 {
      line-height: 24px;
    }
  }
}

.promoFormContainer {
  max-width: 430px;
  margin-left: auto
}

.popup-close {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  margin-right: 2%;
  margin-top: 1%;
  width: 4%;
  z-index: 10;
  opacity: 0;

  &:hover {
    color: #fff;
  }
}

.promo-hero a {
  text-decoration: underline;
}

.tooltip-container {
  position: relative;
  display: inline-block;

  i {
    position: relative;
    z-index: 9999;
  }

  .tooltip-inner {
    font-size: 14px;
    color: #fff;
    margin-left: 35px;
    max-width: 360px;
    margin-bottom: 10px;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}

.tooltip-container .tooltip-text {

  visibility: hidden;

  opacity: 0;
  transition: opacity 0.3s;
}

//.tooltip-container .tooltip-text a {
//  color: white;
//  text-decoration: underline;
//}

.tooltip-container:hover .tooltip-text,
.tooltip-container .tooltip-text:hover {
  visibility: visible;
  opacity: 1;
}

.image-container {
  position: relative;
  display: inline-block;
}

.main-image {
  display: block;
  width: 100%;
  height: auto;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  cursor: pointer; /* Optional: change cursor to pointer */
}

.clever-link {
  cursor: pointer;
}

.call-section .call-icn {
  width: 160px;
  margin-bottom: 35px;
}

.call-section a {
  color: #1E1D29;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
  padding: 0 45px;
}

.call-section [class^="col-"]:first-child a:hover {
  color: #987ebc;
}

.call-section [class^="col-"]:last-child a:hover {
  color: #f498da;
}

.apply-credit .panel-group {
  .panel-heading {
    margin-top: 20px;
  }

  .panel-body {
    background: #fff;
    border: 1px solid;
    border-radius: 13px;
    padding: 14px 15px 20px 15px;
    margin-top: 20px;
    position: relative;

    .btn-arrow {
      background: transparent;
      border: none;
    }

    label {
      color: #1E1D29;
      font-weight: 500;
      position: relative;

      span.hint {
        color: #706F74;
        font-weight: 400;
        font-style: italic;
        font-size: 13px;
        margin: 5px 0 15px 0;
      }

      &.tooltip-container {
        .tooltip-inner {
          margin-left: 35px;
          bottom: 14px;
          left: 0;
          text-align: left;

          &:after {
            background-color: #fff;
          }

          ul {
            margin: 10px 0;

            li {
              position: relative;
              margin-bottom: 6px;
              padding-left: 15px;

              &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 10px;
                width: 4px;
                height: 4px;
                background: #fff;
                border-radius: 100px;
              }
            }
          }
        }

      }
    }

    .panel-body {
      padding: 0;
    }
  }

  .panel-heading {
    .arrow-icon img {
      transform: rotate(180deg);
      margin-left: 5px;
    }

    .plus-sign-icon {
      font-size: 35px;
      font-weight: 500;
    }
  }

}

.calculator-individual-offer-text {
  font-size: 13px;
  text-align: center;
}