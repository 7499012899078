.owl-managers-videos-theme {
    .owl-nav {
        .owl-prev, .owl-next {
            color: var(--gray-dark) !important;

            border: 1px solid var(--gray-dark) !important;

            &:hover {
                background-color: #F4DADB !important;
                border-color: #F4DADB !important;
            }
        }
    }
}