@media only screen and (min-width: 992px) and (max-width: 4000px) {
  .home header .head-block {
    justify-content: flex-end !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 4000px) {
  body.home header .header-block {
    justify-content: flex-end !important;
  }

  body.home header.fixed .header-block {
    justify-content: center !important;
  }

  .otpSigningAgreementTooltip {
    bottom: 50px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 4000px) {

}

@media only screen and (min-width: 1500px) and (max-width: 1730px) {
  .promo-hero {
    min-height: 830px;;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .promo-hero {
    min-height: 715px;

    .form-block {
      margin-right: -95px;
    }

    form {
      padding: 25px 25px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1700px) {
  .calculator {
    left: 0;
    width: 385px;
    top: 220px;
  }

  .calculator-holder:before {
    top: -130px;
  }

  .calculator-holder .nav-amounts-sec {
    top: 172px;
    left: 40px;
  }

  .calculator-holder .nav-amounts-sec a {
    font-size: 18px;
  }

  .calculator .slider-holder .selected-val {
    margin: 0 0 -15px;
  }

  .calculator .nav-instalments a {
    padding: 0 0 0 25px;
  }

  .intro .calculator form .form-group {
    margin-bottom: 12px;
  }

  .intro .calculator-holder form .form-group .form-control {
    font-size: 13px;
  }

  .calculator .button {
    padding: 6px 15px;
    font-size: 14px;
  }

  .intro .intro-container .tab-content:first-child .irs--round .irs-handle:after {
    width: 38px;
    height: 38px;
  }

  .intro .calculator .irs--round .irs-handle {
    width: 34px;
    height: 34px;
  }

  .intro .calculator .irs--round .irs-bar {
    height: 11px;
  }
  .intro .calculator .irs--round .irs-line {
    height: 10px;
  }


  .calculator .slider-holder .selected-val span {
    font-size: 22px;
  }

  .calculator .buttons button {
    padding: 10px 17px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1660px) {

  body.home header .header-block {
    margin-right: 34px;
  }
  header .logo img {
    height: 90px;
  }

  header nav ul li {
    margin: 0 16px;
  }

  header nav ul li a {
    font-size: 14px;
  }

  header .button {
    padding: 12px 42px;
    font-size: 14px;
  }

  header .phone-info {
    margin-top: 33px;
    margin-right: -118px;

    a {
      font-size: 17px;
    }

    img {
      width: 22px;
      margin-right: 5px;
      margin-top: -5px;
    }
  }

  header.fixed-block .phone-info,
  body.outside-header header .phone-info {
    margin-top: 22px;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .sec-intro.intro-about-us {
    min-height: 465px;
    padding: 65px 0 35px 0;
  }

  header nav ul li {
    margin: 0 12px;
  }

  header .phone-info {
    margin-right: -94px;
  }

  body.home header .header-block {
    margin-right: 44px;
  }

  .sec-intro.intro-card {
    min-height: 480px;

    .text {
      padding: 280px 0 20px 0;

      h1 {
        font-size: 40px;
        line-height: 54px;
        padding-right: 212px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  header.fixed-block .phone-info, body.outside-header header .phone-info {
    margin-right: 0;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1365px) {
  header .phone-info {
    margin-top: 80px;
    margin-right: 30px;

    a {
      font-size: 18px;
    }

    img {
      width: 23px;
    }
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {


  .blog .blog-item .blog-content h3 {
    height: 162px;
  }

  .call-to-action h5 {
    font-size: 19px;
    line-height: 32px;
    margin: 0 0 2px 0;
  }
  .our-benefits .benefit {
    padding: 0;
  }

  .intro-about-us {
    padding: 50px 0 30px 0;
  }

  .owl-credits.owl-carousel .owl-stage-outer:before {
    height: calc(100% - 15px);
    width: 50%;
  }

  .credits .owl-theme .owl-nav .owl-next {
    right: -41px;
  }

  .credits .owl-theme .owl-nav .owl-prev {
    left: -41px;
  }

  .credits .owl-theme .owl-nav [class*=owl-] {
    width: 36px;
    height: 36px;
  }

  .credits .owl-theme .owl-nav button span {
    width: 36px;
    height: 36px;
    line-height: 32px;
  }

  .short-contact .info-block .val {
    font-size: 17px;
    padding: 0 25px;
    min-height: 79px;
  }

  .sec-intro {
    min-height: 300px;
  }

  .sec-intro .text {
    padding: 201px 0 40px 0;
  }

  .sec-intro.intro-card {
    .text {
      padding: 190px 0 20px 0;

      h1 {
        font-size: 36px;
        line-height: 48px;
        padding-right: 140px;
      }
    }
  }

  footer .social li {
    margin: 0 5px 0 5px;
  }

  footer .social li a {
    width: 39px;
    height: 39px;
    line-height: 39px;
  }

  .intro-contact h1 {
    margin-top: 132px;
    margin-bottom: 145px;
  }

  .contact-blogs .address-and-time {
    padding-left: 57px;
  }

  .contact-blogs .address-and-time.active span.open {
    margin-left: 57px;
  }

  .contact-blogs .address-and-time h4 {
    font-size: 15px;
  }

  .contact-blogs .address-and-time img {
    width: 49px;
  }

  .contact-blogs .address-and-time .contact-info {
    font-size: 14px;
  }

  .blog .blog-item .blog-img img {
    height: 145px;
  }

  .viva-family .heading {
    margin: 0 50px 28px;
  }

  .free-position .positions-block .see-more a {
    padding: 12px 27px;
  }

  .what-next .contact-inf p {
    padding: 0 17px;
  }

  .selected-contact-info a.button {
    padding: 12px 30px;
    font-size: 14px;
  }


  //  calculator

  .intro .calculator-holder:before {
    top: -125px;
    width: 1230px;
  }

  .calculator-holder .nav-amounts-sec {
    left: 30px;
    top: 163px;
  }

  .intro .logo-calculator {
    top: 55px;
  }

  .intro .calculator-holder svg {
    top: 210px;
  }

  .intro .calculator {
    top: 205px;
    left: -25px;
    width: 400px;
  }

  .calculator .slider-holder {
    padding-bottom: 20px;
  }

  .intro .calculator .button {
    margin-top: 10px;
  }

  .calculator .nav-instalments {
    margin: 2px 0 12px 0;
  }

  .intro .calculator form .form-group {
    margin-bottom: 12px;
  }

  .intro .intro-text {
    left: auto;
    bottom: auto;
    width: 51%;
    margin-top: 55px;
  }

  .testimonial-slider .testimonial-block p {
    padding: 0 52px;
  }

  .characterizes-us {
    .desc {
      font-size: 14px;
      line-height: 19px;
      margin-top: -20px;
    }

    img {
      height: auto;
    }
  }
  form .questionnaire-slide .splide__slide.is-active {
    padding: 0 45px 125px 15px;
  }

  body.home header .header-block {
    margin-right: 15px;
  }

  header nav ul li {
    margin: 0 8px;

    a {
      font-size: 14px;
    }
  }
  header .button {
    padding: 10px 38px;
    font-size: 14px;
  }

  header.fixed-block,
  body.outside-header header {
    nav ul li a {
      font-size: 13px;
    }

    .phone-info {
      margin-top: 23px;
      margin-right: -19px;

      a {
        font-size: 15px;
      }

      img {
        width: 18px;
        margin-right: 2px;
      }
    }
  }

  .calculator .icheck-success.credit-insurance {
    margin-right: -205px;
  }

  .promo-hero {
    min-height: 615px;
    padding: 30px 0 64px 0;

    .form-block {
      margin-right: -40px;
    }

    form {
      h4 {
        font-size: 24px;
        margin-bottom: 24px;
      }

      .form-control {
        padding: 12px 22px;
      }
    }
  }

}

@media only screen and (min-width: 767px) and (max-width: 1200px) {
  .newsletter .lab {
    font-size: 32px;
    line-height: 42px;
  }

  header .logo img {
    height: 84px;
  }

  .typical-example .typical-info {
    line-height: 34px;
    font-size: 25px;
  }

  .see-how-page.get-benefits:after {
    background-size: cover;
    background-position-y: 0;
  }

  .faq-nav a .image-bgr-red {
    width: 173px;
  }

  .promotions .promotion-block h3, h2 {
    font-size: 21px;
    line-height: 29px;
    padding: 0 15px;
  }

  .promotions .promotion-block .term {
    font-size: 16px;
    line-height: 22px;
    margin: 15px 0 20px 0;
  }

  .promotions .promotion-block .term span {
    font-size: 19px;
    line-height: 26px;
  }
  section .ck-content table {
    max-width: 900px;
  }
  .questionnaire-modal .modal-dialog .modal-content:after {
    background-size: cover;
  }


  //calculator

  .intro {
    min-height: 935px;
  }

  .intro .intro-container {
    height: 790px;
    margin-top: 85px;
  }


  .intro .intro-text h1 {
    font-size: 35px;
    line-height: 42px;
    margin: 0 0 13px 0;
  }

  .intro .intro-text p {
    margin: 0 0 23px 0;
  }


  .vivacredit-now .money-info {
    margin-top: 0;
  }

  .short-contact.second-block {
    padding-bottom: 70px;
  }

  .how-to-participate .participate-block {
    padding: 0;

    h5 {
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 0px) and (max-width: 1200px) {
  .credits .owl-credits .item {
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .intro-about-us {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #000;
      opacity: 0.1;
      pointer-events: none;
    }
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .owl-theme .owl-nav button span {
    width: 30px;
    height: 32px;
    line-height: 24px;
  }

  .owl-theme .owl-nav .owl-next {
    right: -34px;
  }

  .owl-theme .owl-nav [class*=owl-] {
    width: 29px;
    height: 29px;
  }
  .owl-theme .owl-nav .owl-prev {
    left: -35px;
  }

  .credits {
    padding-bottom: 20px;
  }

  .credits .owl-theme .owl-nav [class*=owl-] {
    top: 5px;
  }

  .blog.slider .owl-theme .owl-nav .owl-prev {
    left: -35px;
  }

  .blog.slider .owl-theme .owl-nav .owl-next {
    right: -35px;
  }

  .call-to-action h5 {
    font-size: 20px;
    line-height: 27px;
    margin: 0 0 2px 0;
  }

  .call-to-action span,
  .call-to-action a.learn-more {
    font-size: 15px;
  }

  .call-to-action .set-price,
  .call-to-action a.button {
    margin-top: 10px;
  }

  .how-to-get .lab {
    font-size: 24px;
    line-height: 31px;
  }

  .how-to-get .val {
    font-size: 16px;
    line-height: 22px;
  }

  .how-to-get .step {
    height: 315px;
  }

  .how-to-get.sec:after {
    background-size: cover;
  }

  .how-to-get .step:first-child {
    margin-top: 40px;
  }

  .owl-credits.owl-carousel .owl-stage-outer:before {
    height: calc(100% - 16px);
    width: 100%
  }

  .short-contact .info-block {
    margin-bottom: 28px;
  }

  .short-contact .info-block .val {
    font-size: 16px;
    line-height: 1.4;
    min-height: auto;
  }

  .thank-you-content .short-contact .info-block .val {
    min-height: 60px;
  }

  .short-social {
    margin: 25px auto 0;
  }

  .text h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .intro-about-us {
    padding: 50px 0 28px 0;
  }

  .intro-about-us .text .heading {
    margin: 0;
  }

  .intro-about-us span {
    font-size: 18px;
  }

  .sec-intro {
    min-height: 262px;
  }

  .sec-intro .text {
    padding: 156px 0 40px 0;
  }

  .get-money .lab {
    font-size: 16px;
    line-height: 1.4;
    padding: 0;
  }

  .loan-repayment img, .get-money img {
    height: 70px;
    margin: 0 0 13px 0;
  }

  .loan-repayment .lab {
    font-size: 16px;
    line-height: 1.4;
  }

  .loan-repayment .sub-heading {
    font-size: 18px;
    margin: 0 0 50px 0;
  }

  .stage .small-heading {
    margin: 40px 0 12px 0;
    font-size: 31px;
    line-height: 37px;
  }

  .stage .stage-desc {
    font-size: 16px;
    line-height: 1.4;
    padding: 0;
    margin: 0 0 20px 0;
  }

  .see-how-page.get-benefits h3.heading {
    margin: 0 0 20px 0;
  }

  .stage .stage-img {
    margin-top: 40px;
  }

  .stage.stage-phone .stage-link span {
    margin-left: -21px;
  }

  .short-contact:after {
    background-size: cover;
  }

  .intro-contact h1 {
    font-size: 50px;
    line-height: 60px;
    margin-top: 149px;
    margin-bottom: 145px;
  }

  .short-contact.second-block .info-block.online-apply {
    margin-top: 0;
  }

  .short-contact.second-block .info-block.online-apply .eclipse {
    width: 60px;
    height: 60px;
  }

  .short-contact.second-block .info-block.online-apply .eclipse img {
    width: 38px;
  }

  .short-contact .info-block img {
    height: 55px;
    width: 44px;
  }

  .short-contact.second-block .contact-articles .short-social {
    margin-top: 5px;
  }

  .google-map .how-to-find {
    width: 690px;
    margin-right: -345px;
  }

  .google-map .how-to-find .form-group:last-of-type .select2-container {
    width: 435px !important;
  }

  .google-map .form-control {
    font-size: 15px;
  }

  .blog .up-to-date-info {
    padding: 40px 80px 30px 43px;
  }

  .blog .blog-item .blog-img img {
    height: 165px;
  }

  .viva-credit-employer .awards {
    display: block;
    margin: 35px auto 0 auto;
  }

  .viva-credit-employer .video-image {
    margin: 0 0 25px 0;
  }

  .viva-family .heading {
    margin: 0 0 25px;
  }

  .viva-family p {
    margin-bottom: 15px;
  }

  .viva-family img {
    margin: 45px 0 12px 0;
  }

  .success-app:after {
    background-position-y: 0;
  }

  .success-app p {
    font-size: 28px;
  }

  .what-next .contact-inf p {
    padding: 0;
    font-size: 17px;
    line-height: 1.3;
  }

  .what-next .contact-inf img {
    width: 50px;
  }

  .selected-contact-info h1 {
    margin: 25px 0 30px 0;
  }

  .selected-contact-info .contact-info {
    margin: 0 0 20px 0;
  }

  .selected-contact-info p {
    margin: 50px 0 0 0;
  }

  .promotion h1 {
    font-size: 32px;
    line-height: 40px;
    margin: 40px 0 35px 0;
  }
  .promotion .term {
    font-size: 20px;
  }

  .promotion .term span {
    font-size: 23px;
  }

  .promotion .promotion-content {
    padding: 0 25px;
  }

  //calculator

  .intro {
    min-height: 900px;
  }

  .intro .intro-text {
    width: 100%;
    right: auto;
    bottom: auto;
    top: 0;
    left: 0;
    padding-left: 135px;
    margin-top: 25px;
  }


  .intro .calculator-holder:before {
    width: 1175px;
    height: 1104px;
    top: -185px;
    margin-top: 110px;
  }
  .intro .calculator-holder svg {
    height: 161px;
    top: 323px;
    left: 47px;
    width: 125px;
  }

  .calculator-holder .nav-amounts-sec {
    left: -12px;
    top: 202px;
  }

  .intro .calculator {
    width: 390px;
    top: 260px;
    left: -20px;
  }

  .intro .calculator-total .selected-val span {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 0 1px;
  }

  .intro .calculator .slider-holder .selected-val {
    margin: -7px 145px -4px 0;
  }

  form .form-group {
    margin-bottom: 20px;
  }

  .intro .calculator form {
    padding: 0;
  }

  .calculator .icheck-success label {
    margin: 5px 0;
  }

  .calculator #nav-home2 form .form-group {
    margin-bottom: 16px;
  }

  .calculator .icheck-success label.invalidField {
    position: absolute;
    top: -18px;
    width: 202px;
  }

  .calculator .icheck-success.credit-insurance {
    margin-right: -150px;
  }

  .service-rating .heading {
    font-size: 22px;
    margin: 0 0 30px 0;
  }

  .service-rating .rate-question,
  .service-rating .rate-block .form-group label {
    font-size: 18px;
  }

  .service-rating .rate-block .rate-icons .form-group label img {
    width: 62px;
    height: 62px;
  }

  .service-rating .rate-block .rate-icons .form-group label span {
    font-size: 22px;
    margin-top: 5px;
  }

  .service-rating .rate-block form .button {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
  }

  .service-rating .rate-block .form-group textarea {
    height: 140px;
  }

  .service-rating .rate-block .rate-icons .form-group label.active:after {
    height: 30px;
  }

  .service-rating .rate-block .rate-icons .form-group label.active:before {
    margin-bottom: 9px;
  }

  .testimonial-slider .testimonial-block p {
    min-height: 136px;
    padding: 0;
  }

  .testimonial-slider .testimonial-block .customer-info {

    font-size: 18px;
    margin: 30px auto 0 auto;
  }

  .our-benefits .benefit {
    padding: 0 6px;
  }

  .our-benefits .benefit .img-block {
    height: 66px;
  }

  .our-benefits .benefit .img-block img {
    max-height: 66px;
  }

  .heading-3 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 35px;
  }

  .who-are-we h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 35px;
  }

  .our-values {
    .value-block {
      padding: 22px 6px 26px 6px;
      margin-top: 0 !important;

      &:before {
        width: 112%;
        height: 110%;
        margin-left: -10px;
        margin-top: -14px;
      }

      img {
        height: 50px;
      }

      h5 {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .characterizes-us {
    img {
      height: 108px;
      width: 109.5px;
    }

    .desc {
      font-size: 14px;
      line-height: 19px;
      margin-top: -16px;
    }
  }

  .come-to-the-team {
    .heading {
      margin-bottom: 25px;
    }

    p {
      margin-bottom: 15px;
      font-size: 15px;
    }

    .button {
      font-size: 14px;
      line-height: 20px;
      padding: 12px 23px;
    }
  }

  form .questionnaire-slide .splide__slide.is-active {
    padding: 0 15px 125px 15px;
  }

  .questionnaire-slide .splide__arrow.splide__arrow--prev {
    left: 50%;
    margin-left: -100px;
  }

  .questionnaire-slide .splide__arrow.splide__arrow--next {
    right: 50%;
    margin-right: -100px;
  }

  .questionnaire-slide .form-check {
    margin-right: 25px;
  }

  .tooltip-inner {
    bottom: 11px;
    margin-left: -150px;
  }

  #additionalServiceBox {
    bottom: 62px;
  }

  header .phone-info {
    margin-top: 24px;
    margin-right: 85px;

    a {
      font-size: 20px;
    }

    img {
      width: 24px;
      margin-right: 5px;
    }
  }

  header.fixed-block .phone-info,
  body.outside-header header .phone-info {
    margin-top: 18px;
    margin-right: 85px;
  }

  .sec-intro.intro-card {
    .text {
      padding: 130px 170px 0 0;

      h1 {
        font-size: 26px;
        line-height: 40px;
        padding-right: 0;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .viva-white .way-info {
    .small-heading {
      font-size: 27px;
      line-height: 38px;
      margin-bottom: 20px;
    }
  }

  .video-modal .modal-dialog {
    max-width: 80%;
  }

  .viva-smart-block {
    margin-bottom: 35px;

    img {
      height: 125px;
    }

  }

  .calculator .buttons {
    min-width: 466px;

    button {
      font-size: 13px;
      padding: 10px 25px;
      min-width: 220px;
    }
  }

  .promo-hero {
    min-height: auto;

    .form-block {
      margin: 0;
    }
  }
  .call-section .call-icn {
    width: 140px;
    margin-bottom: 30px;
  }

  .blog .blog-item .blog-content h3 {
    margin: 0 0 15px 0;
    min-height: 163px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 991px) {

  header nav ul.nav-items {
    position: fixed;
    left: 0;
    text-align: center;
    width: 100%;
    padding: 15px 0;
    background: #fff;
    right: -100%;
    top: 0;
    transform: translateX(100%);
    transition: all .5s;
    overflow: scroll;
    z-index: 10002;

  }

  header nav ul.nav-items.open {
    height: 100%;
    padding-bottom: 80px;
    right: 0 !important;
    transform: translateX(0);
  }

  header nav ul li {
    display: block;
    margin-bottom: 15px;
  }

  header nav ul li a {
    color: #000;
    font-size: 16px;
  }

  header nav ul.nav-items .nav-btn {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 28px;
  }

  header nav ul.nav-items a.logo {
    text-align: left;
    margin-bottom: 30px;
    margin-left: 15px;
  }

  body.open header.fixed-show {
    height: 100%;
  }

  header nav ul li .dropdown {
    opacity: 1;
    pointer-events: initial;
    position: relative;
    left: 0;
    top: 0;
    text-align: center;
    display: table;
    margin: 10px auto 0 auto;
    padding: 7px 15px 7px 15px;
  }
  header nav ul li .dropdown li {
    margin-bottom: 0;
    margin-top: 10px;
    &:nth-child(2) {
      margin-top: 0;
    }
  }

  header nav ul li.mega-menu {
    padding-bottom: 8px;
  }

  header nav ul li .dropdown li a {
    font-size: 13px;
  }

  header nav ul li .dropdown li.arrow-down, header nav ul li .dropdown li.arrow {
    text-align: center;
    font-weight: 600;
    color: #e3001b;
    margin: 0 auto 5px auto;

  }

  header nav ul li .dropdown li.arrow-up {
    transform: rotate(180deg);
    margin-bottom: 0;
  }

  header nav ul li .dropdown li.arrow img {
    filter: brightness(0) saturate(100%) invert(10%) sepia(88%) saturate(4426%) hue-rotate(345deg) brightness(122%) contrast(115%);
  }

  .nav-btn {
    z-index: 10000;
    width: 34px;
    height: 19px;
    cursor: pointer;
    position: relative;
    margin: 0 15px 0 0;
    display: inline-block;
    padding-right: 0;
    top: 1px;
  }

  .nav-btn span {
    display: block;
    border-radius: 20%;
    height: 4px;
    background-color: white;
    position: absolute;
    right: 0;
    top: 0;

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);

    //-webkit-transition: .1s ease-in-out;
    //-moz-transition: .1s ease-in-out;
    //-o-transition: .1s ease-in-out;
    //transition: .1s ease-in-out;

    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  body.outside-header .nav-btn span {
    background: #3c5a5d;
  }

  .nav-btn span:nth-child(1) {
    width: 100%;
  }

  .nav-btn span:nth-child(2) {
    width: 100%;
    top: 7px;
  }

  .nav-btn span:nth-child(3) {
    width: 100%;
    top: 14px;
  }

  .nav-btn:hover span:nth-child(2) {
    width: 100%;
  }

  .nav-btn:hover span:nth-child(3) {
    width: 100%;
  }

  .nav-btn.open span {
    box-shadow: none;
    background-color: #3c5a5d;
    right: -5px;
  }

  .nav-btn.open span:nth-child(1) {
    width: 100%;
    transform: rotate(45deg);
    top: -3px;
  }

  .nav-btn.open span:nth-child(2) {
    opacity: 0;
  }

  .nav-btn.open span:nth-child(3) {
    width: 100%;
    transform: rotate(-45deg);
    top: 22px;
  }

  nav.open {
    height: 100%;
  }

  .term-and-amount .calculator-holder.show {
    position: static;
    background: transparent;
    padding: 30px 0;
    box-shadow: none;
  }

  .apply-credit .term-and-amount {
    margin-top: 35px;
  }

  .product-info {
    background: #FFF7F7;
  }

  .product-info:after {
    display: none;
  }

  .blog .image-ads {
    border-radius: 12px 12px 0 0;
  }
  .blog .up-to-date-info {
    border-radius: 0 0 12px 12px;
  }

  ul.payment-methods.policy {
    min-width: auto;
  }

  .calculator #nav-home2 {
    margin-top: 0;
  }
  .call-section a {
    padding: 0;
  }

}

@media only screen and (min-width: 575px) and (max-width: 767px) {

  .blog .blog-item .blog-content h3 {
    min-height: 144px;
    margin-bottom: 15px;
  }

  .blog .blog-item .blog-img img {
    height: 120px;
  }

  .thank-you-content .short-contact .info-block .val {
    min-height: 70px;
    padding: 0 13px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {

  .space {
    padding: 45px 0;
  }

  .heading {
    margin: 0 0 30px 0;
    font-size: 28px;
    line-height: 36px;
  }

  .subheading {
    font-size: 20px;
  }

  .small-heading {
    font-size: 23px;
    line-height: 30px;
  }

  .outside-header {
    padding-top: 71px;
  }

  body.home header {
    position: relative;
    background-color: #fff;
    box-shadow: 0 10px 6px rgba(0, 0, 0, .02);
    padding: 8px 0;

    .phone-info a {
      color: #e3001b;
    }

    .nav-btn {
      span {
        background: #3c5a5d;
      }
    }
  }

  header .logo img,
  body.outside-header header .logo img,
  header.fixed .logo img {
    height: 55px;
  }
  .button {
    padding: 10px 32px;
  }

  form .form-group {
    margin-bottom: 12px;
  }

  .sec-intro {
    min-height: inherit;
  }

  .text {
    padding: 150px 0 10px 0;
  }

  .text h1 {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 10px;
  }

  .text p {
    font-size: 16px;
  }


  .how-to-get .sticky-top {
    height: inherit;
    margin-top: 0;
  }

  .how-to-get .step:first-child {
    margin-top: 0;
  }

  .how-to-get .lab {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 5px 0;
    text-align: center;
  }

  .how-to-get .val {
    font-size: 15px;
    line-height: 21px;
    text-align: center;
  }

  .how-to-get .num {
    font-size: 100px;
    line-height: 100px;
    min-width: 60px;
    text-align: center;
  }

  .how-to-get .step {
    height: inherit;
    padding: 15px 0;
  }

  .how-to-get .steps h6 {
    font-size: 26px;
    margin: 0 0 10px 0;
  }

  .see-how-page.get-benefits:after {
    background-size: cover;
    left: 0;
    background-position-y: 0;
  }

  .how-to-get.sec:after {
    background-size: cover;
  }

  .stage .stage-desc {
    color: #3A3636;
    font-size: 16px;
    line-height: 22px;
    padding: 0;
    margin: 0 0 15px 0;
  }

  .stage.stage-phone .stage-link {
    margin: 0 0 50px 0;
  }

  .stage.stage-phone .stage-link a {
    font-size: 28px;
    line-height: 34px;
  }

  .stage .stage-img {
    margin: 0 0 15px 0;
  }


  .newsletter .lab {
    font-size: 26px;
    line-height: 32px;
    margin: 0 0 20px 0;
  }

  .newsletter form button {
    line-height: 22px;
    position: inherit;
    top: inherit;
    right: inherit;
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    margin: 15px auto 0 auto;
    display: table;
    padding: 11px 0;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    text-transform: uppercase;
    border-radius: 50px;
    width: 70%;
  }

  .newsletter form button:hover {
    background: #ffffff;
  }

  .newsletter form .form-control {
    padding: 0 20px 0 20px;
  }


  //blog slider page

  .button {
    font-size: 13px;
  }

  .blog-article h1 {
    margin-bottom: 20px;
  }

  .info-blog-article h2 {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 32px;
    font-weight: normal;
  }

  .blog-article .calendar {
    margin-bottom: 20px;
    margin-top: -18px;
  }

  .blog-article .calendar span,
  .blog-article .back-to-blog a {
    font-size: 15px;
  }

  .blog-article .blog-image {
    margin-bottom: 25px;
  }

  .blog-article .info-blog-article p {
    margin-bottom: 18px;
    font-size: 14px;
  }

  .blog-article .info-blog-article div {
    font-size: 14px;
    margin-bottom: 18px;
  }

  .blog-article .info-blog-article p strong {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 15px;
  }

  .blog-article .info-blog-article ul li {
    font-size: 14px;
  }
  .blog-article .info-blog-article ul li strong {
    font-size: 15px;
  }


  .blog.slider .owl-nav {
    bottom: 0;
    top: auto;
    width: 100%;
    left: 0;
    margin-bottom: -47px;
    margin-left: 0;
  }

  .owl-theme .owl-nav [class*=owl-] {
    position: static;
    margin: 10px 10px 0 10px !important;
  }

  .blog .blog-item .blog-content {
    padding: 16px;
  }
  .blog .blog-item .blog-content h3 {
    font-size: 16px;

  }

  .button-outline {
    font-size: 13px;
    padding: 10px 30px;
    line-height: 23px;
  }

  .blog.slider {
    padding: 45px 0 100px 0;
  }

  .blog.slider .owl-theme .owl-dots {
    display: none;
  }

  .call-to-action h5 {
    font-size: 19px;
    line-height: 23px;
    margin: 0;
  }

  ul.page-items li a img {
    margin-top: -1px;
  }

  .call-to-action span,
  .call-to-action a.learn-more {
    font-size: 14px;
  }

  .call-to-action .set-price span {
    font-size: 20px;
  }

  .call-to-action .set-price,
  .call-to-action a.button {
    margin-top: 10px;
  }

  .call-to-action.show {
    margin-top: 70px;
  }

  .call-to-action a.button {
    padding: 16px 35px 15.5px 35px;
  }

  .credits .owl-credits .item {
    background: #E8C3C3;
  }

  .credits .owl-credits {
    margin: 30px 0 0 0;
  }

  .credits .owl-nav {
    position: static;
    margin: 20px 0 20px 0;
  }

  .credits .owl-theme .owl-nav [class*=owl-] {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(224, 205, 205, 0.31976540616246496) 0%);
    width: 36px;
    height: 36px;
    margin-top: 25px !important;
  }

  .owl-theme .owl-nav button span {
    width: 36px;
    height: 36px;
    line-height: 32px;
  }

  .credits .owl-credits .item {
    min-height: 98px;
    padding: 15px 8px;
  }

  .credits-info .heading {
    margin: 0 0 20px 0;
  }

  .credits-info p {
    font-size: 17px;
    margin-bottom: 0;
  }

  .credits {
    padding-bottom: 20px;
  }

  .credits h2 {
    font-size: 23px;
    line-height: 31px;
    margin-bottom: 10px;
  }

  .credits p {
    margin: 0 0 18px 0;
    line-height: 22px;
  }

  p {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .blog.slider .owl-theme .owl-nav [class*=owl-] {
    width: 36px;
    height: 36px;
  }

  .short-contact .info-block {
    padding: 20px 10px;
    margin-bottom: 25px;
  }

  .short-contact .info-block img {
    margin: 0 0 10px 0;
    height: 55px;
    max-width: 46px;
  }

  .short-contact .info-block .val {
    font-size: 16px;
    line-height: 1.3;
    padding: 0;
    min-height: auto;
  }

  .viva-smart-for:after {
    background-size: 100% 100%;
  }

  .short-social li a {
    width: 42px;
    height: 42px;
    line-height: 43px;
  }

  .short-social {
    margin: 10px auto 0;
  }

  .newsletter {
    padding: 45px 0;
    text-align: center;
  }

  .newsletter .hint {
    font-size: 15px;
    line-height: 1.4;
  }

  .newsletter form .form-control {
    height: 46px;
    font-size: 14px;
  }

  .sec-intro .text {
    padding: 156px 0 40px 0;
  }

  .get-money .lab {
    font-size: 15px;
    line-height: 1.3;
    padding: 0 17px;
  }

  .loan-repayment img,
  .get-money img {
    height: 65px;
    margin: 35px 0 10px 0;
  }

  .get-money .heading {
    margin-bottom: 0;
  }

  .get-money .lab a {
    font-size: 14px;
    line-height: 20px;
  }

  .loan-repayment .lab {
    font-size: 15px;
    line-height: 1.4;
  }

  .loan-repayment .sub-heading {
    font-size: 16px;
    line-height: 1.4;
    margin: 0;
  }

  ul.footer-menu {
    margin-bottom: 30px;
  }

  footer .footer-bot {
    margin: 30px 0 0 0;
  }

  footer .honor-block img {
    max-width: 130px;
  }
  footer .honor-block .badge2 {
    max-width: 143px;
  }

  footer .footer-menu li {
    margin: 13px 0;
  }

  footer .footer-menu li a {
    font-size: 15px;
  }

  footer .social li a {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  .vivacredit-now .money-info,
  .vivacredit-standard .money-info {
    margin: 15px 0 0 0;
  }

  .info {
    font-size: 17px;
    margin-bottom: 15px;
    line-height: 23px;
  }

  ul.list-info {
    margin-bottom: 20px;
  }

  ul.list-info li {
    font-size: 15px;
    padding-left: 26px;
  }

  .product-info {
    padding: 45px 0 30px;
  }

  .product-info .product-features .feature {
    padding: 16px 0;
  }

  .product-info .product-features .feature .lab {
    font-size: 17px;
    margin: 0 0 5px 0;
  }

  .product-info .product-features .feature .lab img {
    margin-right: 9px;
    top: -2px;
    height: 19px;
  }

  .product-info .product-desc p {
    margin: 25px 0 0 0;
  }

  .product-info .product-features .feature .val {
    font-size: 15px;
    line-height: 1.4;
  }

  .product-info .product-features .feature:last-child {
    margin-bottom: 10px;
  }

  .stage.stage-address .small-heading {
    margin: 0 0 10px 0;
  }

  .stage.stage-address a.button {
    margin-bottom: 50px;
  }

  .stage.stage-phone .stage-link span {
    margin-left: 0;
  }

  .stage.stage-address .stage-link {
    font-size: 16px;
  }

  .stage.stage-address .stage-link img {
    margin-right: 6px;
    height: 18px;
    top: -3px;
  }

  .typical-example .typical-title {
    margin: 0 0 15px 0;
  }

  .typical-example .typical-info {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .general-information h1 {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 30px;
  }
  .typical-example p {
    margin: 15px 0 0 0;
  }

  .short-contact:after {
    background: url("../images/short-contact-shape.svg") top right fixed no-repeat;
  }

  .typical-example a {
    margin-right: 0;
    font-size: 16px;
  }

  .faq-nav a .viva-info {
    padding: 0 7px;
    margin-left: -66px;
    font-size: 14px;
    margin-top: -13px;
  }

  .faq-nav a:hover .image-bgr-red,
  .faq-nav a .image-bgr-red,
  .faq-nav a .arrow {
    display: none;
  }

  .faq-nav a .image-bgr-red-mobile {
    display: none;
  }

  .faq-nav a:hover .image-bgr-red-mobile {
    display: inline-block;
    position: absolute;
    left: 0;
    z-index: -1;
  }

  .faq-nav a:hover .viva-info {
    margin-top: -8px;
    margin-left: -66px;
    font-size: 13px;
    padding: 0 12px;
  }


  .contact-info {
    margin-top: 35px;
    text-align: left;
  }

  .contact-info .message-info {
    display: table;
    margin: 15px auto 0 auto;
  }
  .contact-info .message-info img {
    width: 28px;
    margin-right: 8px;
  }

  .contact-info .message-info a {
    font-size: 17px;
  }

  .contact-info .phone-info {
    display: table;
    margin: 0 auto;
  }

  .contact-info .phone-info img {
    width: 28px;
    margin-right: 8px;
  }

  .contact-info .phone-info a {
    font-size: 26px;
    line-height: 33px;
  }


  .contact-info p {
    text-align: center;
    margin: 0 auto 20px auto;
  }

  .faq .question {
    padding: 15px 27px 15px 45px;
    font-size: 17px;
  }

  .general-information .accordion-item .question-logo {
    width: 35px;
  }

  .faq .accordion-item .answer-content {
    padding: 0;
  }

  .faq .accordion-item .answer-content p {
    font-size: 15px;
    margin-bottom: 15px;
    line-height: 24px;
    word-break: break-word;
  }

  .intro-contact h1 {
    margin-top: 175px;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 42px;
  }

  .short-contact.second-block {
    padding: 45px 0;
  }

  .short-contact.second-block .contact-articles {
    margin-top: 0;
  }

  .short-contact.second-block {
    padding: 45px 0;
  }

  .short-contact.second-block .info-block.online-apply {
    margin-top: 0;
  }

  .short-contact.second-block .info-block.online-apply .eclipse {
    width: 58px;
    height: 58px;
  }

  .short-contact.second-block .info-block.online-apply .eclipse img {
    width: 36px;
  }

  .short-contact.second-block .info-block .location-office {
    margin-top: 13px;
  }

  .short-contact.second-block .info-block .location-office span {
    font-size: 15px;
  }

  .short-contact.second-block .online-apply .work-days {
    margin: 13px 0;
  }

  .short-contact.second-block .info-block .val small {
    font-size: 12px;
    line-height: 16px;
  }

  .google-map .how-to-find h5 {
    margin: 0 0 18px 0;
    font-size: 18px;
  }

  .google-map .form-control {
    font-size: 15px;
  }

  .google-map .how-to-find {
    padding: 20px 30px 15px 30px;
    right: 0;
    margin: -150px 15px 0 0;
    max-width: calc(100% - 30px);
  }

  .google-map .how-to-find .form-group {
    margin-bottom: 20px;
    display: block;
  }

  .google-map .how-to-find .form-group:last-of-type .select2-container,
  .google-map .how-to-find .form-group:first-of-type .select2-container {
    width: 100% !important;
  }

  .google-map .how-to-find .form-group:first-of-type {
    margin-right: 0;
  }

  .loc-block {
    width: 100%;
  }

  .gm-style .gm-style-iw-a {
    width: 90%;
  }

  .gm-style .gm-style-iw-d div {
    text-align: left;
    line-height: 19px;
  }

  .gm-style .gm-style-iw-c {
    min-width: auto !important;
  }

  .gm-style div {
    width: 100%;
  }

  .gmnoprint,
  .gmnoprint div {
    width: auto;
  }

  .contact-blogs {
    padding-bottom: 5px;
  }

  .contact-blogs .address-and-time {
    padding-left: 55px;
    margin-bottom: 40px;
    padding-top: 30px;
    height: auto;
  }

  .contact-blogs .address-and-time h4 {
    font-size: 15px;
    line-height: 1.4;
  }

  .contact-blogs .address-and-time img {
    width: 47px;
  }

  .contact-blogs .address-and-time .contact-info img {
    bottom: 1px;
    position: relative;
  }

  .contact-blogs .address-and-time .contact-info {
    font-size: 14px;
    line-height: 1.4;
  }

  .contact-blogs .address-and-time .contact-info span:nth-child(1) {
    margin-top: 8px;
  }

  .contact-blogs .address-and-time.active span.open {
    margin-left: 55px;
  }

  .contact-blogs .address-and-time a {
    margin-top: 13px;
  }

  .blog h1 {
    margin: 0 0 30px 0;
  }

  .blog .up-to-date-info {
    padding: 25px 15px 30px 15px;
  }

  .blog .up-to-date-info h2 {
    font-size: 20px;
    line-height: 1.3;
  }

  .blog .up-to-date-info .short-social {
    margin: 15px auto 20px;
  }

  .blog .up-to-date-info .short-social a {
    width: 40px;
    height: 40px;
    line-height: 43px;
    font-size: 18px;
  }

  .blog .up-to-date-info .see-more a img {
    width: 14px;
  }

  .blog .blog-item {
    margin-top: 30px;
  }

  ul.page-items {
    padding: 30px 0 0 0;
  }

  .blog-article .heading {
    font-size: 24px;
    line-height: 32px;
  }

  .blog-article .calendar {

  }

  .viva-credit-employer p {
    font-size: 15px;
    margin: 0 0 35px 0;
    line-height: 23px;
  }

  .text-title {
    font-size: 28px;
    line-height: 36px;
  }

  .apply-for-work h2 {
    margin: 0 0 18px 0;
  }

  .viva-credit-employer .video-image {
    margin: 0 0 8px 0;
  }

  .viva-credit-employer .awards {
    max-height: 50px;
    max-width: 190px;
    display: block;
    margin: 35px auto 0 auto;
  }


  .viva-family .heading {
    margin: 0 0 18px;
  }

  .viva-family p {
    font-size: 17px;
    margin: 0;
    line-height: 23px;
  }

  .viva-family img {
    height: 60px;
    margin: 35px 0 10px 0;
  }

  .viva-family .lab {
    font-size: 16px;
    line-height: 1.4;
    padding: 0 20px;
  }

  .apply-for-work p {
    margin-bottom: 18px;
  }

  .apply-for-work img {
    margin-top: 18px;
  }

  .free-position .positions-block {
    padding: 10px 15px 20px 15px;
    margin: 18px 0 0 0;
  }

  .free-position .heading {
    margin: 0 0 12px 0;
  }

  .free-position .positions-block .address,
  .free-position .positions-block .time {
    margin-top: 7px;
    font-size: 15px;
  }

  .free-position .see-more {
    margin: 10px 0;
    justify-content: center;
  }

  .see-more a img {
    width: 14px;
  }

  .free-position .see-all {
    margin: 18px 0 0 0;
  }

  .free-position .see-all a {
    padding: 12px 20px;
  }

  .specialist-location-and-apply-btn {
    margin-bottom: 20px;
  }

  .customer-service h1 {
    margin-bottom: 10px;
  }

  .customer-service h5 {
    font-size: 20px;
    margin: 0 0 12px 0;
    line-height: 25px;
  }

  .customer-service p {
    margin-bottom: 30px;
  }

  .customer-service ul li {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 15px;
    padding-left: 11px;
  }

  .customer-service ul li:before {
    margin-top: 10px;
  }

  .success-app h3 {
    margin: 20px 0;
  }

  .success-app p {
    font-size: 19px;
    line-height: 1.4;
    margin-top: 25px;
  }

  .success-app .logo-success {
    margin-bottom: 25px;
  }

  .success-app .thank-you-small-text {
    font-size: 15px !important
  }

  .success-app .logo-success img {
    width: 95px;
  }

  .success-app:after {
    background-position-y: 0;
  }

  .circle {
    height: 150px;
    width: 150px;
    margin-left: -73px;
    margin-top: -15px;
  }

  .circle:before {
    top: -18px;
    left: -10px;
  }

  .promotions .promotion-block {
    padding-bottom: 20px;
    margin: 0 0 30px 0;
  }

  .promotions .promotion-block h3, h2 {
    font-size: 20px;
    margin-top: 10px;
    line-height: 28px;
    padding: 0 15px;
  }

  .promotions .promotion-block .promotion-img img {
    border-radius: 18px 18px 0 0;
  }

  .promotions .promotion-block .term {
    font-size: 15px;
    margin: 12px 0 20px 0;
  }

  .promotions .promotion-block .term img {
    height: 14px;
  }

  .promotions .promotion-block .term span {
    font-size: 18px;
  }

  .what-next p {
    font-size: 17px;
    margin: 0;
    line-height: 23px;
  }
  .what-next .contact-inf {
    margin-top: 35px;
  }


  .what-next .contact-inf img {
    width: 50px;
    margin-bottom: 10px;
  }

  .what-next .contact-inf p {
    padding: 0;
    font-size: 15px;
    line-height: 1.3;
  }

  .what-next .contact-inf .short-social {
    margin: -20px auto 0;
  }

  .selected-contact-info h1 {
    font-size: 18px;
    line-height: 27px;
    margin: 20px 0 20px 0;
  }

  .selected-contact-info .contact-info {
    margin: 0 0 18px 0;
  }

  .selected-contact-info p {
    margin: 35px 0 0 0;
  }

  .promotion h1 {
    font-size: 26px;
    line-height: 34px;
    margin: 40px 0 25px 0;
  }

  .promotion .term {
    font-size: 18px;
  }

  .promotion .term img {
    width: 19px;
    margin: -2px 2px 0 0;
  }

  .promotion .term span {
    margin-left: 12px;
    display: block;
    font-size: 19px;
    line-height: 35px;
  }

  .promotion .promotion-content {
    padding: 0;
  }

  .promotion .promotion-content .promotion-list li {
    font-size: 15px;
    padding-left: 28px;
  }

  .promotion .promotion-img {
    margin: 0 0 25px 0;
  }

  .promotion .promotion-content p {
    margin: 30px 0;
  }


  //  calculator

  .intro {
    padding: 0;
    min-height: auto;
  }

  .mobile-intro {
    padding: 25px 15px 50px 15px;
  }

  .intro .intro-container {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .intro .calculator-holder {
    order: 2;
  }

  .intro .calculator-holder svg {
    position: absolute;
    display: block;
    right: 0;
    height: 152px;
    width: 119px;
    left: auto;
    top: 0;
    border-radius: 0 12px 0 0;
  }

  .intro .calculator {
    position: relative;
    width: 100%;
    background: #F2EEEE;
    padding: 12px 15px 45px 15px;
    left: 0;
    border-radius: 0;
    top: 0;
  }

  .calculator-holder .nav-amounts-sec {
    transform: none;
    top: 0;
    background: #fff;
    width: 100%;
    left: 0;
    margin-top: 0;
    border-radius: 0;
    padding: 8px;
  }

  .calculator .buttons {
    min-width: auto;
    //border-top: 1px solid #C0C6C8;
    //margin-top: 12px;
    //padding-top: 5px;

    button {
      width: 100%;
      padding: 8px 15px;
      height: 45px;
    }
  }

  .intro .intro-text {
    position: static;
    margin-top: 70px;
    text-align: left;
    width: 100%;
    min-height: 60px;
  }

  .intro .intro-text p {
    margin-bottom: 0;
    font-size: 14px;
  }

  .intro .intro-text a.button-outline {
    word-break: break-word;
  }

  .intro .intro-text h1 {
    font-size: 22px;
    line-height: 32px;
    margin: 25px 0 10px 0;
  }

  .calculator-holder:before {
    display: none;
  }

  .intro .logo-calculator {
    position: relative;
    left: 0;
  }

  .calculator .icheck-success.credit-insurance {
    position: relative;
    margin: 0;
  }
  .calculator .icheck-success.credit-insurance .insurance-info {
    font-size: 12px;

    div {
      margin: 5px 0 0 0;
    }
  }

  [class*=icheck-] > input:first-child + input[type=hidden] + label::before, [class*=icheck-] > input:first-child + label::before {
    margin-top: -1px;
  }
  [class*=icheck-] > input:first-child:checked + input[type=hidden] + label::after, [class*=icheck-] > input:first-child:checked + label::after {
    top: -2px;
  }

  .intro .logo-calculator img {
    width: 125px;
  }

  .intro .calculator .tab-content {
    margin-top: 52px;
  }

  .intro .calculator-holder .calculator .irs--round {
    max-width: 100%;
  }

  .intro .calculator .slider-holder .selected-val span {
    font-size: 20px;
    line-height: 30px;
  }

  .intro .intro-container .tab-content:first-child .irs--round .irs-handle:after, .term-and-amount .calculator-holder .tab-content:first-child .irs--round .irs-handle:after {
    width: 34px;
    height: 34px;
    margin-top: -18px;
    margin-left: -18px;
  }

  .irs--round .irs-handle {
    top: 16px;
    width: 28px;
    height: 28px;
  }

  .calculator-holder .nav-amounts-sec a {
    font-size: 16px;
  }

  .calculator .icheck-success label {
    font-size: 12px;
  }

  .calculator-holder span.next {
    width: 26px;
    height: 26px;
    bottom: 0;

    img {
      width: 7px;
      margin: 0 0 4px 1px;
    }
  }
  .calculator .button span.next {
    top: 2px;
  }

  .calculator-total .contract-info {
    font-size: 13px;
    margin-top: 15px;
  }

  .intro .calculator .nav-instalments {
    margin: 10px 0 25px 0;
    display: block;
  }

  .intro .calculator .nav-instalments a,
  .term-and-amount .calculator-holder .calculator .nav-instalments a {
    margin: 0 0 15px 0;
    display: block;
    padding: 0 0 0 28px;
    font-size: 14px;
  }

  .intro .calculator .slider-holder {
    padding-bottom: 35px;
  }

  .intro .calculator .button {
    margin: 20px auto 4px;
  }

  .intro .calculator form {
    padding: 0;
  }

  .intro .calculator .icheck-success label {
    margin-top: 6px;
    margin-bottom: 0;
  }

  .apply-credit .term-and-amount {
    padding: 15px 15px 17px 15px;
  }

  .term-and-amount .calculator-holder.show {
    padding: 30px 0 0 0;
  }

  .term-and-amount .calculator-holder .calculator .nav-instalments {
    display: block;
  }

  .call-me form .button {
    width: 100%;
  }

  .promotion .internally-nav a {
    font-size: 16px;
  }

  .promotion .promotion-content .promotion-list {
    margin: 0;
  }

  .home header .logo {
    display: block;
  }

  .home header .logo img.white-logo {
    display: inline-block;
  }

  .home header .logo img.black-logo {
    display: none;
  }

  .home header.fixed .logo img.white-logo {
    display: none;
  }

  .home header.fixed .logo img.black-logo {
    display: inline-block;
  }

  .payment-methods .radio label span {
    display: inline-block;
    max-width: 145px;
    vertical-align: middle;
  }

  .calculator .icheck-success label:nth-child(3) {
    margin-top: 0 !important;
    padding-top: 25px;
    display: block;
  }

  .calculator .icheck-success label.invalidField:before {
    top: 23px;
  }

  .calculator .icheck-success label.invalidField:after {
    top: 23px !important;
  }

  .calculator .icheck-success label.invalidField {
    top: 4px;
  }

  #bigAmountsForm .form-group {
    margin-bottom: 25px;
  }

  .intro .calculator .icheck-success label.invalidField:before {
    top: -17px;
  }

  .intro .calculator .icheck-success label:nth-child(3) {
    margin-top: 16px !important;
  }

  .intro .calculator .icheck-success label.invalidField {
    bottom: -12px;;
  }

  .intro .calculator .icheck-success label.invalidField:after {
    top: -19px !important;
  }

  .intro .calculator .icheck-success.credit-insurance label.invalidField:before {
    top: -14px;
  }

  .intro .calculator .icheck-success.credit-insurance input:first-child:checked + label.invalidField:before {
    top: -80px;
  }

  .intro .calculator .icheck-success.credit-insurance label.invalidField:after {
    top: -82px !important;
  }
  .intro .calculator #nav-home2 .icheck-success label.invalidField {
    bottom: -13px;
  }

  section .ck-content table {
    max-width: 600px;
    width: 600px !important;
  }

  section .ck-content div {
    font-size: 14px;
  }

  .service-rating .heading {
    font-size: 19px;
    margin: 0 0 25px 0;
    line-height: 30px;
  }

  .service-rating .rate-question,
  .service-rating .rate-block .form-group label {
    font-size: 14px;
  }

  .service-rating .rate-block .rate-icons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .service-rating .rate-block .rate-icons .form-group {
    margin-bottom: 10px;
  }

  .service-rating .rate-block .rate-icons .form-group label img {
    width: 55px;
    height: 55px;
  }

  .service-rating .rate-block .rate-icons .form-group label span {
    font-size: 20px;
    margin-top: -5px;
  }

  .service-rating .rate-block .form-group textarea {
    font-size: 15px;
    height: 110px;
    padding: 8px 15px;
  }

  .service-rating .rate-block form .button {
    padding: 12px 32px;
    width: 100%;
    font-size: 14px;
  }

  .service-rating .rate-block .rate-icons .form-group label.active:before {
    width: 8px;
    height: 14px;
    margin-bottom: 8px;
    margin-left: -4px;
  }

  .service-rating .rate-block .rate-icons .form-group label.active:after {
    height: 26px;
  }

  .testimonial-slider .testimonial-block p {
    padding: 0;
    min-height: auto;
    margin-top: 15px;
  }
  .testimonial-slider .testimonial-block .customer-info {
    font-size: 18px;
    line-height: 22px;
  }

  .testimonial-slider .owl-theme .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    margin: 5px;
  }

  .testimonial-slider .testimonial-block {
    margin-bottom: 20px;
  }

  .testimonial-slider .testimonial-block img {
    width: 35px;
    margin-top: 10px;
  }

  .our-benefits .benefit {
    padding: 0;
    font-size: 12px;
    margin: 16px 0;
  }

  .our-benefits .benefit .img-block {
    height: 50px;
  }

  .our-benefits .benefit .img-block img {
    max-height: 50px;
  }

  .intro-about-us {
    padding: 128px 0 20px 0;

    .text .heading {
      margin: 0;
    }

    span {
      font-size: 16px;
    }
  }
  .heading-3 {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .who-are-we h2 {
    font-size: 23px;
    line-height: 32px;
    margin-bottom: 25px;
  }

  .our-values {
    background: url("../images/our-values-mobile.jpg") center no-repeat;
    background-size: cover;

    .value-block {
      padding: 8px 17px 15px 17px;
      max-width: 225px;
      margin: 0 auto 5px auto;
      height: auto;
      min-height: 296px;

      &:before {
        background-size: 100%;
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-top: -9px;
      }

      img {
        height: 45px;
        margin-bottom: 6px;
      }

      h5 {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
      }
    }

    .col-lg-4:nth-child(2) .value-block,
    .col-lg-4:last-child .value-block {
      margin: 0 auto 5px auto;
    }
  }

  .characterizes-us .desc {
    font-size: 16px;
    margin-top: -40px;
  }

  .come-to-the-team {
    img {
      margin: 5px 0 30px 0;
    }

    .button {
      padding: 10px 23px;
      font-size: 12px;
    }
  }

  form .questionnaire-slide .splide__slide.is-active {
    padding: 0 0 170px 0;
  }

  .questionnaire-block {
    padding-top: 0;
  }

  .questionnaire-modal .modal-dialog {
    margin: 35px 10px 35px 10px;
  }

  .questionnaire-slide h5 {
    font-size: 18px;
    line-height: 24px;
  }

  .questionnaire-modal .modal-dialog .modal-content {
    padding-bottom: 0;
  }

  .questionnaire-modal .modal-header .close {
    right: 1rem;
    top: 0;
  }

  .questionnaire-slide .form-check {
    margin: 0 12px 15px 0;
  }

  .questionnaire-slide .form-check img {
    width: 86px;
    height: 90px;
    border: 3px solid #ccc;
  }
  .questionnaire-slide .form-check p {
    font-size: 14px;
  }

  .questionnaire-modal form .prevBtn,
  .questionnaire-modal form .nextBtn {
    margin-bottom: 80px;
    font-size: 15px;
  }

  .questionnaire-modal form .prevBtn {
    left: 50%;
    margin-left: -140px;
  }

  .questionnaire-modal form .nextBtn {
    right: 50%;
    margin-right: -140px;
  }

  .questionnaire-slide .prevBtn img, .questionnaire-slide .nextBtn img {
    width: 50px;
  }

  .my-slider-progress {
    top: -130px;
  }

  .close-questionnaire {
    padding: 7px 14px;
    left: 0;
    margin: 0 0 10px 0;
  }

  .questionnaire-modal .modal-dialog .modal-content:after {
    background-size: cover;
  }

  .questionnaire-text {
    padding: 25px 15px 0 0;
  }

  .questionnaire-text h3 {
    font-size: 17px;
    margin-bottom: 15px;
  }

  .questionnaire-text p {
    margin-bottom: 15px;
  }

  .questionnaire-text h4 {
    font-size: 17px;
  }

  .questionnaire-text {
    padding: 25px 15px 0 0;
  }

  .questionnaire-text h3 {
    font-size: 17px;
    margin-bottom: 15px;
  }

  .questionnaire-text p {
    margin-bottom: 15px;
  }

  .questionnaire-text h4 {
    font-size: 17px;
  }

  .payment-methods label {
    font-size: 13px;
  }

  .tooltip-third .tooltip-inner {
    margin-right: 0;
  }

  .tooltip-inner {
    z-index: 2000;
    right: 0;
    bottom: 0;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: -75px;
    width: 265px;
  }

  .icheck-success {
    position: relative;
  }
  #additionalServiceBox {
    left: 0;
    margin-left: 40px;
    max-width: 258px;
    bottom: 7px;
  }
  .questionnaire-modal .modal-header {
    padding-top: 15px;
    padding-bottom: 18px;
  }

  .questionnaire-block .questionnaire-head-info {
    font-size: 20px !important;
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 20px;

    i {
      float: none;
      margin: 0;
    }
  }

  .checkbox-item {
    width: 30px;
    height: 30px;
    margin-top: 53px;
    margin-left: 8px;
  }

  header .phone-info {
    margin-top: 14px;
    margin-right: 79px;

    a {
      font-size: 15px;
    }

    img {
      width: 19px;
      margin-right: 2px;
    }
  }

  header.fixed-block .phone-info,
  body.outside-header header .phone-info {
    margin-top: 14px;
    margin-right: 80px;
  }

  .sec-intro.intro-card {
    margin-bottom: -1px;

    background-size: cover !important;
    background-position: center !important;

    .text {
      padding: 65px 55px 5px 0;

      h1 {
        font-size: 20px;
        line-height: 30px;
        padding-right: 0;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .viva-white .way-info {

    margin-bottom: 35px;

    .small-heading {
      margin-bottom: 10px;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .faq-cont {
    padding: 50px 0;

    .accordion-item {
      margin: 0 0 20px 0;

      .question {
        padding: 15px 0 15px 45px;

        &:after {
          display: none;
        }
      }
    }

    .see-all {
      font-size: 15px;
    }
  }

  .video-slider {
    padding-bottom: 100px;

    .owl-theme .owl-nav {
      bottom: 0;
      top: auto;
      width: 100%;
      left: 0;
      //margin-bottom: -47px;
      margin-bottom: -140px;
      margin-left: 0;

      button {
        &.owl-prev span {
          padding-right: 0;
        }

        &.owl-next span {
          padding-left: 3px;
        }
      }
    }
  }

  .apply-credit {
    h5 {
      font-size: 21px;
      position: relative;
    }

    &.additional-data .tooltip-text {
      margin-bottom: 32px;
      margin-left: 28px;
      right: 0;
      left: auto;
      margin-right: 0;

      &:before {
        display: none;
      }
    }

    .panel-group {
      .panel-body {
        label {
          &.tooltip-container .tooltip-inner {
            font-size: 13px;
            &:after {
              height: 28px;
              left: -12px;
              width: 12px;
            }
            ul li {
              padding-left: 11px;
            }
          }
        }
      }
    }
  }

  .smart-example {
    .small-heading {
      text-align: center;
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 25px;
    }
  }

  .viva-smart-block {
    margin-bottom: 35px;

    img {
      height: 130px;
    }

    h5 {
      padding: 0;
      max-width: 225px;
      margin: 0 auto;
    }
  }
  .promo-hero {
    //background: url('../images/promo-landing2.jpg') center no-repeat !important;
    background-size: cover !important;
    min-height: 1600px;
    display: block;
    padding-top: 30px;

    .form-block {
      margin: 0;
    }

    form {
      border-radius: 27px;
      padding: 16px 8px;

      h4 {
        font-size: 20px;
        margin-bottom: 14px;
      }

      .form-control {
        padding: 10px 22px;
      }

      .form-check label {
        font-size: 12px;
      }
    }
  }

  .how-to-participate .participate-block {
    padding: 0;

    h5 {
      line-height: 22px;
      max-width: 200px;
      margin: 0 auto;
      font-size: 16px;
    }

    img {
      margin-bottom: 10px;
    }
  }
  .call-section .call-icn {
    margin-bottom: 30px;
  }

  .call-section a {
    margin: 25px 0;
  }

  .calculator-individual-offer-text {
    padding-top: 20px;
  }

  #desktopCalculatorSection {
    background: none !important;
  }

  .otpSigningAgreementTooltip {
    bottom: 90px !important;
  }
}


@media only screen and (min-width: 575px) and (max-width: 767px) {
  .video-modal .modal-dialog {
    max-width: 95%;
  }

  .promo-hero {
    padding-top: 30px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 575px) {
  .data-progress .progress-step .val {
    font-size: 15px;
  }

  .intro-about-us {
    background-position: -44px 0;
  }
  .data-progress .progress-step.active .val:before {
    margin-left: -22px;
    margin-top: -22px;
    width: 44px;
    height: 44px;
  }
  .data-progress .progress-step .val:after {
    margin-left: -22px;
    margin-top: -22px;
    width: 44px;
    height: 44px;
  }

  .data-progress .progress-step .val span {
    top: -11px;
  }

  .data-progress .progress-step .lab {
    font-size: 12px;
  }

  .data-progress {
    padding: 47px 8px 14px 8px;
  }

  .call-to-action a.button {
    display: table;
    margin: 10px auto 0 auto;
    padding: 13px 35px 12.5px 35px;
  }

  .call-to-action .set-price button {
    width: 35px;
    height: 35px;
  }

  .call-to-action .set-price {
    margin-top: 5px;
  }

  .sec-intro .text {
    padding: 46px 0 10px 0;
  }

  .text h1 {
    font-size: 25px;
    line-height: 34px;
  }

  .product-details .text h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .loan-repayment .lab {
    padding: 0 15px;
  }

  .short-contact.second-block .info-block.find-office {
    margin-top: 0;
  }

  .intro-contact h1 {
    margin-top: 88px;
    font-size: 25px;
    line-height: 34px;
  }

  .blog .blog-item .blog-content h3 {
    min-height: auto;
  }

  ul.page-items li {
    padding: 0 4px;
  }

  ul.page-items .page-item .page-link {
    padding: 8px 10px 10px 10px;
    margin-left: 0;
    width: 34px;
    height: 34px;
    font-size: 16px;
  }

  .blog .blog-item .blog-img img {
    height: auto;
  }

  .blog.slider .blog-item .blog-content h3 {
    min-height: 144px;
  }

  .thank-you-content .short-contact .info-block .val {
    min-height: auto;
    padding: 0 7px;
  }
  .questionnaire-slide .form-check p {
    max-width: none;
    width: 138px;
  }

  .video-modal {
    display: none;
  }
  .video-slider .video-btn {
    display: none;
  }
  .iframe-container iframe {
    pointer-events: inherit;
  }

  .promo-hero {
    padding-top: 160px;
  }

  .popup-close {
    margin-top: 0;
  }
}

@media only screen and (min-width: 0px) and (max-width: 425px) {
  .how-to-get .sticky-top img {
    display: none;
  }

  .how-to-get .sticky-top img:last-child {
    display: block;
    padding: 0;
  }

  .how-to-get .heading {
    margin-bottom: 30px;
  }
}


@media only screen and (min-width: 0px) and (max-width: 359px) {
  .data-progress {
    padding: 47px 5px 14px 0;
  }
  .data-progress .step-02 .lab {
    margin-left: -16px;
  }

  .questionnaire-slide .form-check p {
    width: 118px;
    font-size: 13px;
  }
}


@media only screen and (min-width: 0px) and (max-width: 330px) {
  .intro .calculator .icheck-success label.invalidField:before {
    top: -31px;
  }
  .intro .calculator .icheck-success label.invalidField:after {
    top: -32px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 320px) {
  .our-benefits .benefit {
    font-size: 11px;
  }
}

@media only screen and (min-width: 1250px) and (max-width: 4000px) {
  .video-slider .owl-theme .owl-nav button {
    &.owl-prev {
      left: -65px;
    }

    &.owl-next {
      right: -65px;
    }
  }
}